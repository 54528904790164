import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Card, Grid, useTheme, TextField, Select, InputLabel, FormControl } from '@mui/material';

import LandIcon from '@mui/icons-material/Forest'; // Use appropriate icon
import YearBuildIcon from '@mui/icons-material/Construction'; // Use appropriate icon
import RenovationCostIcon from '@mui/icons-material/AttachMoney'; // Use appropriate icon
import FloorSizeIcon from '@mui/icons-material/Fullscreen'; // Use appropriate icon
import BuildingTypeIcon from '@mui/icons-material/Home'; // Use appropriate icon
import PurchaseYearIcon from '@mui/icons-material/Event';

import { tokens } from "../../../theme";


import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CategoryIcon from '@mui/icons-material/Category';
import EventIcon from '@mui/icons-material/Event';
import PercentIcon from '@mui/icons-material/Percent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StructureIcon from '@mui/icons-material/Build';
import BusinessIcon from '@mui/icons-material/Business';
import { Auth } from "aws-amplify";
import AuthenticatedAPI from "../../../auth_helper";
import { Link, useNavigate } from 'react-router-dom';
import { apiKey, useFetchpropertCoordinates, useStreetViewImage } from '../../../hooks/properties/properties';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { BankingInfo } from '../../../types/User';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import ShowerIcon from '@mui/icons-material/Shower';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import { toast } from 'react-toastify';
import MenuItem from '@mui/material/MenuItem';
import { updateBankingInfo } from '../../../features/userSlice';
import CashFlowAnalysis from '../cashFlowAnalysis';
import AcquisitionCosts from '../../components/cashFlow/acquisitionCosts';
import PropertyDetailss from '../../components/cashFlow/propertyDetails'
import AnnualHoldingCosts from '../../components/cashFlow/annualHoldingCosts';
import { updateAnnualHoldingCosts } from '../../../features/cashFlow';
import CashFlowProjection from '../../components/cashFlow/cashFlowProjection';
import Income from '../../components/cashFlow/income';

interface LendingRate {
    rate: number;
}

interface AccountMeta {
    lendingRates: LendingRate[];
}

interface SelectedAccount {
    meta: AccountMeta;
    balance: any
}

// Assuming selectedAccount is of this type
// Define your selectedAccount accordingly

const PropertyDetails: React.FC = () => {

    const [propertyDetails, setPropertyDetails] = useState<any>(null);
    // const [imageUrl, setImageUrl] = useState('');
    const [propertyId, setPropertyId] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        propertyManager: propertyDetails?.data.property_manager || '',
        realEstateAgent: propertyDetails?.data.real_estate_agent || '',
        solicitor: propertyDetails?.data.solicitor || '',
    });
    const [selectedAccount, setSelectedAccount] = useState<SelectedAccount | any>('select account');
    const [isDisabled, setIsDisabled] = useState(false);


    const cashFlowAnalysis = useSelector(
        (state: RootState) => state.cashFlowAnalysis
    );







    const handleChange = async (event: any) => {
        if (!event.target.value.accountNo) {
            return
        }


        console.log("Selected account:", event.target.value.accountNo); // Debugging step
        await updateAccountNumber(event.target.value.accountNo)
        setSelectedAccount(event.target.value);
    };
    // Handle input changes
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const dispatch = useDispatch<AppDispatch>();
    const imageUrl = useStreetViewImage(propertyDetails?.data[0]?.address);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate()
    const userProfile = useSelector(
        (state: RootState) => state.user.personalInfo
    );
    const userBankingInfo = useSelector<RootState, BankingInfo>(
        (state) => state.user.bankingInfo
    );



    const fetchUserBankDetails = async (id: any) => {
        try {
            const user = await Auth?.currentAuthenticatedUser();
            const response = await AuthenticatedAPI.request(
                "FinanceAPI",
                "post",
                `/banking/${user.username}`,
                {
                    // body: {
                    //   userId: userProfile.personalInfo.id,
                    //   profile: userProfile,
                    // },
                }
            );


            dispatch(
                updateBankingInfo({
                    consent: response.consent[0],
                    accounts: response.accounts.data,
                    consentUrl: response.consentUrl,
                })
            );
        } catch (error) {
            console.log("Error saving user profile:", error);
            // Handle error state
        }
    };


    const getPropertyDetailHandler = async (id: string) => {

        try {
            const userDatastart = await Auth.currentAuthenticatedUser().then(async (res) => {
                const response = await AuthenticatedAPI.request(
                    'InvestmentAPI',
                    'get',
                    `/api/property-details/${id}`
                );

                if (!response) {
                    throw new Error('Failed to fetch user profile');

                }
                console.log(response, '--->')
                setFormData({
                    propertyManager: response.data[0].property_manager || '',
                    realEstateAgent: response.data[0].real_estate_agent || '',
                    solicitor: response.data[0].solicitor || '',
                });
                return response;
            });

            setPropertyDetails(userDatastart);
        } catch (error) {
            console.error('Error fetching property details:', error);
        }
    };


    console.log(propertyDetails, 'properetetioeiodisoifo')


    useEffect(() => {
        const accountNumber = propertyDetails?.data[0]?.account_number;
        if (accountNumber) {
            const account = userBankingInfo.accounts.find(
                (transaction) => transaction.accountNo === accountNumber
            );
            if (account) {
                setSelectedAccount(account);
                setIsDisabled(true);
            }
        }
    }, [propertyDetails, userBankingInfo.accounts]);







    const openTransactionsPopup = (url: any) => {
        const consentUrl =
            userBankingInfo.consentUrl?.toString() || url?.toString();
        // Open the URL in a popup window
        if (consentUrl) {
            // window.open(transactionsUrl, '_blank', 'width=600,height=400,resizable=yes');
            window.location.href = consentUrl;
        } else {
            console.error("No consent URL found"); // Handle error case if needed
        }
    };
    const addAccount = async (id: any) => {
        // setAddBtnLoading(true);
        try {
            const response = await AuthenticatedAPI.request(
                "FinanceAPI",
                "post",
                `/banking/add-account/${id}`,
                {}
            );

            openTransactionsPopup(response.redirectUrl);
            //   setAddBtnLoading(false);
            // console.log('User profile saved successfully', response);
        } catch (error) {
            //   setAddBtnLoading(false);
            console.error("Error saving user profile:", error);
            // Handle error state
        }
    };;


    //Property Growth
    const investmentProperties =
        propertyDetails?.data || [];
    const data = investmentProperties.map(
        (property: any) =>
            property.currentestimatedvalue - property.currentloanamount || 0
    );
    const PropertyNames = investmentProperties.map(
        (property: any) =>
            property.address || ""
    );
    const propertyName = PropertyNames.map((name: string) => name.split(' ').slice(0, 3).join(' '));
    const totalLoan = investmentProperties.map(
        (loan: any) => loan.currentloanamount || 0
    );

    const netRentalYields = investmentProperties.map(
        (property: any) => parseFloat(property.netrentalyield) || 0
    );

    // Borrowable Equity
    const borrowableEquities = investmentProperties.map((property: any) => {
        const currentEstimatedValue = parseFloat(property.currentestimatedvalue) || 0;
        const currentLoanAmount = parseFloat(property.currentloanamount) || 0;
        const borrowableEquity = (currentEstimatedValue * 0.8 - currentLoanAmount).toFixed(0);
        return parseFloat(borrowableEquity);  // Convert it back to number if needed
    });


    const toatlEquity = borrowableEquities.reduce(
        (total: any, value: any) => total + value,
        0
    )?.toFixed(2);


    const toatlLoanAmount = totalLoan.reduce(
        (total: number, value: any) => total + Number(value),
        0
    );
    const total = toatlEquity + toatlLoanAmount;
    const Equitypercente = ((toatlEquity / total) * 100).toFixed(2);
    const Debtpercente = ((toatlLoanAmount / total) * 100).toFixed(2);

    useEffect(() => { }, [
        toatlEquity,
        Equitypercente,
        Debtpercente,
        toatlLoanAmount,
    ]);
    const LoanToValueratio = ((investmentProperties[0]?.currentloanamount / investmentProperties[0]?.currentestimatedvalue) * 100).toFixed(2); // Calculate loan to value ratio
    // Extract property ID from URL and fetch property details

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const id = query.get('id');
        if (id) {
            setPropertyId(id);
            getPropertyDetailHandler(id);
            if (userBankingInfo.accounts.length == 0) {
                fetchUserBankDetails(userProfile.id)
            }

        }
    }, []);

    const editHandler = () => {
        navigate('/goal-setting?step=3')
    }


    const handleSubmit = async () => {
        try {
            setLoading(true)
            const response = await AuthenticatedAPI.request(
                'InvestmentAPI',
                'put',
                `/api/property/${propertyId}`,
                {
                    body: formData,
                }
            );
            setLoading(false)
            toast.success('Updated successfully!')
            return response;
        } catch (error) {
            // Handle error (e.g., show error message)
            setLoading(false)
            console.error('Error updating property:', error);
            return error;
        }
    };




    const updateAccountNumber = async (account_number: any) => {
        try {
            setLoading(true)
            const response = await AuthenticatedAPI.request(
                'InvestmentAPI',
                'post',
                `/api/property/add-account`,
                {
                    body: {
                        id: propertyId,
                        account_number: account_number
                    },
                }
            );
            setLoading(false)
            // toast.success('Updated successfully!')
            setIsDisabled(true)
            return response;
        } catch (error) {
            // Handle error (e.g., show error message)
            setLoading(false)
            console.error('Error updating property:', error);
            return error;
        }
    };



    // const growth_since_purchase = investmentProperties.currentestimatedvalue - investmentProperties.initialpurchaseprice / (investmentProperties.initialpurchaseprice * 100)
    const initialPurchasePrice = parseFloat(investmentProperties[0]?.initialpurchaseprice || 0);
    const currentEstimatedValue = parseFloat(investmentProperties[0]?.currentestimatedvalue || 0);

    const growth_since_purchase = ((currentEstimatedValue - initialPurchasePrice) / initialPurchasePrice * 100).toFixed(2);
    const width = 600
    const height = 300
    const zoom = 15
    const googleMapsUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${encodeURIComponent(investmentProperties[0]?.address)}`;
    const googleMapsImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(investmentProperties[0]?.address)}&zoom=${zoom}&size=${width}x${height}&key=${apiKey}&markers=color:red|${encodeURIComponent(investmentProperties[0]?.address)}`;
    // const streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=${width}x${height}&location=${investmentProperties[0]?.propertydetails?.location?.coordinates[0]},${investmentProperties[0]?.propertydetails?.location?.coordinates[1]}&key=${apiKey}`;
    // const streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=${width}x${height}&location=${153.05849177},${-27.49572145}&key=${apiKey}`;
    // const backgroundImage = streetViewUrl
    // console.log('investmentProperties[0]?.propertydetails?.location?.coordinates[0]', investmentProperties[0]?.propertydetails?.location?.coordinates[1])
    // useEffect(() => {
    //     const fetchStreetViewImage = async () => {
    //         const params = {
    //             size: '600x300', // Image size
    //             location: investmentProperties[0]?.address, // Address
    //             fov: '80', // Field of view
    //             heading: '70', // Heading (optional)
    //             pitch: '0', // Pitch (optional)
    //             key: apiKey,
    //         };

    //         const url = 'https://maps.googleapis.com/maps/api/streetview';
    //         const queryParams = new URLSearchParams(params).toString();
    //         const imageUrl = `${url}?${queryParams}`;
    //         setImageUrl(imageUrl);
    //     };

    //     fetchStreetViewImage();
    // }, [investmentProperties]);
    return (

        <>

            {/* <Grid container spacing={3} columns={12}>

                <Grid item md={12} lg={12}>
                    <Card
                        sx={{
                            padding: "20px !important",
                            marginBottom: "0px !important",
                            borderRadius: "15px !important",
                            boxShadow: "none !important",
                            backgroundColor: colors.primary[400],
                        }}>
                        <Box
                            sx={{
                                position: 'relative',
                                overflow: 'hidden',
                                backgroundImage: `url(${backgroundImage})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                height: '500px',
                                borderRadius: '8px',
                                marginBottom: theme.spacing(2),
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Box
                                sx={{
                                    background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%)',
                                    padding: theme.spacing(2),
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                {propertyDetails?.data.map((property: any, index: number) => (
                                    <Box>
                                        <Typography variant="h4" color="white">{property.address}</Typography>

                                        <Typography variant="h6" color="white">{filteredPropertyDetails[0]?.attributes?.bathrooms ?? 0} Baths,{filteredPropertyDetails[0]?.attributes?.bedrooms ?? 0} Beds, {filteredPropertyDetails?.attributes?.parkingSpaces ?? 0} Parking spaces</Typography>
                                    </Box>
                                ))}
                                <Button variant="contained" color="primary" onClick={() => editHandler()}>Edit properties</Button>
                            </Box>
                        </Box>


                    </Card >
                </Grid >

            </Grid > */}

            {investmentProperties.map((property: any, index: number) => {
                const netRentalYields = investmentProperties.map((property: any) => {
                    const rentalYield = parseFloat(property.netrentalyield) || 0;
                    return rentalYield.toFixed(2); // Convert to fixed decimal places
                });

                const filteredPropertyDetails = property.propertydetails?.filter(
                    (detail: { id: any }) => detail.id === property.propertyid
                );
                return (

                    <Box className="p-6 portfolio-page">
                        {/* <Box className="p-6 portfolio-banner"
                            sx={{
                                position: 'relative',
                                overflow: 'hidden',
                                backgroundImage: `url(${imageUrl})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                height: '250px',
                                borderRadius: '8px',
                                marginBottom: '30px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                
                            }}
                        >
                            <Box
                                sx={{
                                    background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%)',
                                    padding: theme.spacing(2),
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Box>
                                    <Typography variant="h4" color="white">{property.address}</Typography>


                                    <Typography variant="h6" color="white">{filteredPropertyDetails?.[0]?.attributes?.bedrooms ?? 0} <HotelOutlinedIcon /> {filteredPropertyDetails[0]?.attributes?.bathrooms ?? 0}<ShowerIcon />{filteredPropertyDetails[0]?.attributes?.parkingSpaces ?? 0} <DirectionsCarFilledIcon /></Typography>
                                </Box>
                                <Button variant="contained" color="secondary" onClick={() => editHandler()} sx={{
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "capitalize",
                                    color: "#ffffff",
                                    padding: "10px 28px",
                                }}>Edit properties</Button>
                            </Box>

                        </Box> */}
                        <Box className="map-banner">
                        <iframe
                            src={googleMapsUrl}
                            width='100%'
                            height="400"
                            style={{ border: 0 }}
                            allowFullScreen={true}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            aria-hidden="false"
                            // tabIndex="0"
                            title="property-map"
                        ></iframe>
                        <div className='map-content'>
                        <Box>
                                <Typography variant="h4" color="white">{property.address}</Typography>


                                <Typography variant="h6" color="white">{filteredPropertyDetails?.[0]?.attributes?.bedrooms ?? 0} <HotelOutlinedIcon /> {filteredPropertyDetails[0]?.attributes?.bathrooms ?? 0}<ShowerIcon />{filteredPropertyDetails[0]?.attributes?.parkingSpaces ?? 0} <DirectionsCarFilledIcon /></Typography>
                            </Box>
                            <Button variant="contained" color="secondary" onClick={() => editHandler()} sx={{
                                borderRadius: "10px",
                                fontSize: "14px",
                                fontWeight: "600",
                                textTransform: "capitalize",
                                color: "#ffffff",
                                padding: "10px 28px",
                            }}>Edit properties</Button>
                        </div>
                        </Box>





                        <Grid container spacing={3} columns={12}>
                            <Grid item xs={12} md={6} lg={8}>
                                <div className='divider-box'>
                                    <Card className='box-1'
                                        sx={{
                                            padding: "20px !important",
                                            marginBottom: "30px !important",
                                            borderRadius: "15px !important",
                                            boxShadow: "none !important",
                                            backgroundColor: colors.primary[400],
                                        }}
                                    >
                                        <Typography
                                            variant="h4"
                                            align="left"
                                            pb={2}
                                            zIndex={1}
                                            gutterBottom
                                            sx={{
                                                fontWeight: "700",
                                                width: '100%',
                                                padding: theme.spacing(1),
                                                borderBottom: '2px solid',
                                                borderColor: theme.palette.divider,
                                            }}
                                        >
                                            Property Details - Principal Place of Residence
                                        </Typography>
                                        <Grid container spacing={2} mt={1}>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }} className="card_new">
                                                    <LandIcon sx={{ mr: 1 }} />
                                                    <Typography variant="body2"><strong>Land Size :</strong> {filteredPropertyDetails[0]?.landDetails?.area?.display}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }} className="card_new">
                                                    <YearBuildIcon sx={{ mr: 1 }} />
                                                    <Typography variant="body2"><strong>Year Build :</strong> {filteredPropertyDetails[0]?.buildingDetails?.builtYear}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }} className="card_new">
                                                    <RenovationCostIcon sx={{ mr: 1 }} />
                                                    <Typography variant="body2"><strong>Property Category :</strong> {filteredPropertyDetails[0]?.propertyCategory}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }} className="card_new">
                                                    <BuildingTypeIcon sx={{ mr: 1 }} />
                                                    <Typography variant="body2"><strong>Building Type :</strong> {filteredPropertyDetails[0]?.propertyType}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }} className="card_new">
                                                    <PurchaseYearIcon sx={{ mr: 1 }} />
                                                    <Typography variant="body2"><strong>Purchase Year :</strong> {property.yearofpurchase}</Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12}>
                                                {/* <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                <FloorSizeIcon sx={{ mr: 1 }} />
                                                <Typography variant="body2"><strong>Floor Size :</strong> 157.0</Typography>
                                            </Box>
                                                <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                    <BuildingTypeIcon sx={{ mr: 1 }} />
                                                    <Typography variant="body2"><strong>Building Type :</strong> {filteredPropertyDetails[0]?.propertyType}</Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                    <PurchaseYearIcon sx={{ mr: 1 }} />
                                                    <Typography variant="body2"><strong>Purchase Year :</strong> {property.yearofpurchase}</Typography>
                                                </Box> */}
                                            </Grid>

                                        </Grid>
                                    </Card>
                                    <Card className='box-1'
                                        sx={{
                                            padding: "20px !important",
                                            marginBottom: "30px !important",
                                            borderRadius: "15px !important",
                                            boxShadow: "none !important",
                                            backgroundColor: colors.primary[400],
                                        }}
                                    >
                                        <Typography
                                            variant="h4"
                                            align="left"
                                            pb={2}
                                            zIndex={1}
                                            gutterBottom
                                            sx={{
                                                fontWeight: "700",
                                                width: '100%',
                                                padding: theme.spacing(1),
                                                borderBottom: '2px solid',
                                                borderColor: theme.palette.divider,
                                            }}
                                        >
                                            Acquisition Details
                                        </Typography>
                                        <Grid container spacing={2} mt={1}>
                                            <Grid item xs={12}>
                                                <Box p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                    <TextField
                                                        label="Property Manager"
                                                        variant="outlined"
                                                        size="small"
                                                        name='propertyManager'
                                                        fullWidth
                                                        value={formData.propertyManager}
                                                        onChange={handleInputChange}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                    <TextField
                                                        label="Real estate agent "
                                                        variant="outlined"
                                                        size="small"
                                                        name='realEstateAgent'
                                                        fullWidth
                                                        value={formData.realEstateAgent}
                                                        onChange={handleInputChange}
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Box p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                    <TextField
                                                        label="Solicitor"
                                                        variant="outlined"
                                                        name='solicitor'
                                                        size="small"
                                                        fullWidth
                                                        value={formData.solicitor}
                                                        onChange={handleInputChange}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-end">
                                                <Box p={2} mb={2}>
                                                    <Button variant="contained"
                                                        disabled={loading}
                                                        color="secondary"
                                                        onClick={handleSubmit} sx={{
                                                            borderRadius: "10px",
                                                            fontSize: "14px",
                                                            fontWeight: "600",
                                                            textTransform: "capitalize",
                                                            color: "#ffffff",
                                                            padding: "10px 28px",
                                                        }}>Submit</Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </div>
                                <div className='divider-box'>
                                    <Card className='box-1'
                                        sx={{
                                            padding: "20px !important",
                                            marginBottom: "30px !important",
                                            borderRadius: "15px !important",
                                            boxShadow: "none !important",
                                            backgroundColor: colors.primary[400],
                                        }}
                                    >
                                        <Typography
                                            variant="h4"
                                            align="left"
                                            pb={2}
                                            zIndex={1}
                                            gutterBottom
                                            sx={{
                                                fontWeight: "700",
                                                width: '100%',
                                                padding: theme.spacing(1),
                                                borderBottom: '2px solid',
                                                borderColor: theme.palette.divider,
                                            }}
                                        >
                                            Loan Details
                                        </Typography>
                                        <Grid container spacing={2} mt={1}>
                                            <Grid item xs={12}>
                                                <Box display="flex" alignItems="center" mb={2}>
                                                    {userBankingInfo.consentUrl ? (<Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => openTransactionsPopup("")}
                                                        sx={{
                                                            borderRadius: "10px",
                                                            fontSize: "14px",
                                                            fontWeight: "600",
                                                            textTransform: "capitalize",
                                                            color: "#ffffff",
                                                            padding: "10px 28px",
                                                        }}
                                                    >
                                                        View Finance
                                                    </Button>) : (
                                                        // <Typography variant="body2"><strong>Please add you Account details <Button variant="contained" color="secondary" onClick={() => addAccount(userProfile.id)} sx={{
                                                        //     borderRadius: "10px",
                                                        //     fontSize: "14px",
                                                        //     fontWeight: "600",
                                                        //     width:"50%",
                                                        //     textTransform: "capitalize",
                                                        //     color: "#ffffff",
                                                        //     padding: "10px 28px",
                                                        // }}>Add Account</Button></strong></Typography>

                                                        <>
                                                            <Grid item xs={12} md={12} lg={6}>

                                                                <Typography variant="body2"><strong>Please add your Account details</strong></Typography>

                                                            </Grid>
                                                            <Grid xs={12} md={12} lg={6} display="flex" alignItems="center" justifyContent="flex-end">
                                                                <Box p={2} mb={2}>
                                                                    <Button variant="contained"
                                                                        disabled={loading}
                                                                        color="secondary"
                                                                        onClick={() => addAccount(userProfile.id)} sx={{
                                                                            borderRadius: "10px",
                                                                            fontSize: "14px",
                                                                            fontWeight: "600",
                                                                            textTransform: "capitalize",
                                                                            color: "#ffffff",
                                                                            padding: "10px 28px",
                                                                        }}>Add Account</Button>
                                                                </Box>
                                                            </Grid>
                                                        </>)}

                                                </Box>



                                                {userBankingInfo.accounts
                                                    ?.filter(transaction => transaction.class?.type === "mortgage").length > 0 ? <Grid container spacing={2} mt={1}>

                                                    <Grid item xs={12} md={12} lg={12} xl={6}>
                                                        <FormControl fullWidth>
                                                            {!selectedAccount?.meta && <Typography variant="body2"><strong>Select Mortgage Account</strong></Typography>}
                                                            <Select
                                                                value={selectedAccount || ''}
                                                                onChange={(e) => handleChange(e)}

                                                                sx={{
                                                                    borderRadius: "10px",
                                                                    fontSize: "14px",
                                                                    fontWeight: "600",
                                                                    textTransform: "capitalize",
                                                                    color: "#000",
                                                                    padding: "5px 28px",
                                                                    width: "100%",
                                                                }}
                                                            // disabled={isDisabled}
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    Select Mortgage Account
                                                                </MenuItem>
                                                                {userBankingInfo.accounts
                                                                    .filter((transaction) => transaction.class?.type === "mortgage")
                                                                    .map((transaction, i) => (
                                                                        <MenuItem key={i} value={transaction}>
                                                                            {transaction?.bank_name} - {transaction?.accountNo}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>


                                                        {/* <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }}>
                                            
                                                <Typography variant="body2"><strong>Yield on Loan Amount :</strong> 0.00%</Typography>
                                            </Box> */}
                                                    </Grid>



                                                    {selectedAccount?.meta ? <Grid item xs={12} md={12} lg={12} xl={6}>

                                                        <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                            <Typography variant="body2">
                                                                <strong>Interest Rate : </strong>
                                                                {selectedAccount?.meta && Array.isArray(selectedAccount.meta.lendingRates) && selectedAccount.meta.lendingRates.length > 0
                                                                    ? (selectedAccount.meta.lendingRates[0].rate * 100).toFixed(2)
                                                                    : '0.00'}
                                                                %
                                                            </Typography>
                                                        </Box>

                                                    </Grid> : null}

                                                    {selectedAccount?.meta && <Grid item xs={12} md={12} lg={12} xl={6}>

                                                        <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                            {/* <PercentIcon sx={{ mr: 1 }} /> */}
                                                            <Typography variant="body2"><strong>Current Balance:</strong> {selectedAccount?.balance} </Typography>
                                                        </Box>

                                                    </Grid>}
                                                </Grid> : 'No Mortgage Account Linked!'}
                                            </Grid>

                                        </Grid>
                                    </Card>

                                    <Card className='box-1'
                                        sx={{
                                            padding: "20px !important",
                                            marginBottom: "30px !important",
                                            borderRadius: "15px !important",
                                            boxShadow: "none !important",
                                            backgroundColor: colors.primary[400],
                                        }}
                                    >
                                        <Typography
                                            variant="h4"
                                            align="left"
                                            pb={2}
                                            zIndex={1}
                                            gutterBottom
                                            sx={{
                                                fontWeight: "700",
                                                width: '100%',
                                                padding: theme.spacing(1),
                                                borderBottom: '2px solid',
                                                borderColor: theme.palette.divider,
                                            }}
                                        >
                                            Rental Details
                                        </Typography>
                                        <Grid container spacing={2} mt={1}>
                                            <Grid item xs={12} md={12} lg={12} xl={6}>
                                                {/* <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                    <BusinessIcon sx={{ mr: 1 }} />
                                                    <Typography variant="body2"><strong>Agency :</strong> {filteredPropertyDetails[0]?.rentals[0]?.agencies[0]?.name ?? 'Private'}</Typography>
                                                </Box> */}
                                                <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }} className="card_new">
                                                    <AttachMoneyIcon sx={{ mr: 1 }} />
                                                    <Typography variant="body2"><strong>Rental Income :</strong> ${property?.weeklyrent}</Typography>
                                                </Box>
                                                {/* <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }}>
                                            
                                                <Typography variant="body2"><strong>Yield on Loan Amount :</strong> 0.00%</Typography>
                                            </Box> */}
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={12} xl={6}>
                                                {/* <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}> */}
                                                {/* <PercentIcon sx={{ mr: 1 }} /> */}
                                                {/* <Typography variant="body2"><strong>Management Percentage:</strong> 0.00%</Typography> */}
                                                {/* </Box> */}
                                                <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }} className="card_new">
                                                    <PercentIcon sx={{ mr: 1 }} />
                                                    <Typography variant="body2"><strong>Rental Yield:</strong> {netRentalYields} %</Typography>
                                                </Box>
                                                {/* <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                <Typography variant="body2"><strong>Yield on Purchase Price:</strong> 0.00%</Typography>
                                            </Box> */}
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </div>
                                <Card
                                    sx={{
                                        padding: "20px 20px 0px 20px !important",
                                        marginBottom: "30px !important",
                                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
                                        backgroundColor: colors.primary[400],
                                        width: '100%',  // Ensure full width
                                    }}
                                >
                                    <Typography variant="h3" align="left" pb={3} zIndex={1} gutterBottom>
                                        Cash Flow Projection
                                    </Typography>

                                    <CashFlowProjection
                                        graphs={false}
                                        propertyDetails={cashFlowAnalysis.propertyDetails}
                                        keyAssumptions={cashFlowAnalysis.keyAssumptions}
                                        cashFlowProjection={cashFlowAnalysis.cashFlowProjection}
                                    />
                                </Card>




                                {/* <Card
                                    sx={{
                                        padding: "20px !important",
                                        marginBottom: "30px !important",
                                        borderRadius: "15px !important",
                                        boxShadow: "none !important",
                                        backgroundColor: colors.primary[400],
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        align="left"
                                        pb={2}
                                        zIndex={1}
                                        gutterBottom
                                        sx={{
                                            fontWeight: "700",
                                            width: '100%',
                                            padding: theme.spacing(1),
                                            borderBottom: '2px solid',
                                            borderColor: theme.palette.divider,
                                        }}
                                    >
                                        Sales History
                                    </Typography>
                                    {filteredPropertyDetails[0]?.sales?.map((sales: any, index: number) => {

                                        return (
                                            <Grid container spacing={2} mt={1}>
                                                <Grid item xs={6}>


                                                    <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                        <BusinessIcon sx={{ mr: 1 }} />
                                                        <Typography variant="body2"><strong>Agency : </strong> {sales?.agencies ? sales?.agencies[0]?.name : 'Private'}</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                        <AttachMoneyIcon sx={{ mr: 1 }} />
                                                        <Typography variant="body2"><strong>Price :</strong> {sales?.price?.display}</Typography>
                                                    </Box>

                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                        <CategoryIcon sx={{ mr: 1 }} />
                                                        <Typography variant="body2"><strong>Type :</strong>  {sales?.type}</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                        <EventIcon sx={{ mr: 1 }} />
                                                        <Typography variant="body2"><strong>Date :</strong> {sales?.date}</Typography>
                                                    </Box>
                                                </Grid>


                                            </Grid>
                                        )
                                    })}
                                </Card> */}









                            </Grid>



                            <Grid item xs={12} md={6} lg={4}>
                                <Card
                                    sx={{
                                        padding: "20px !important",
                                        marginBottom: "30px !important",
                                        borderRadius: "15px !important",
                                        boxShadow: "none !important",
                                        backgroundColor: colors.primary[400],
                                    }}
                                >
                                    {/* {propertyDetails?.data.map((property: any, index: number) => ( */}
                                    <Grid container spacing={0}>
                                        {/* <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                <Typography variant="body2"><strong>Purchase Price :</strong> ${property.initialpurchaseprice}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                <Typography variant="body2"><strong>Current Price :</strong> ${property.currentestimatedvalue}</Typography>
                                            </Box>
                                        </Grid> */}


                                        <div className="price-card">
                                            <div className="main-price-sec">
                                                <div className="price-section">
                                                    <span className="label">Purchase Price:</span>
                                                    <span className="value">${property.initialpurchaseprice}</span>
                                                </div>
                                                <div className="price-section">
                                                    <span className="label">Current Price:</span>
                                                    <span className="value">${property.currentestimatedvalue}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </Grid>
                                    {/* ))} */}
                                </Card>


                                <Card className='box-1'
                                    sx={{
                                        padding: "20px !important",
                                        marginBottom: "30px !important",
                                        borderRadius: "15px !important",
                                        boxShadow: "none !important",
                                        backgroundColor: colors.primary[400],
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        align="left"
                                        pb={2}
                                        zIndex={1}
                                        gutterBottom
                                        sx={{
                                            fontWeight: "700",
                                            width: '100%',
                                            padding: theme.spacing(1),
                                            borderBottom: '2px solid',
                                            borderColor: theme.palette.divider,
                                        }}
                                    >
                                        Sales History
                                    </Typography>






                                    
                                    {filteredPropertyDetails[0]?.sales?.map((sales: any, index: number) => {

                                        return (
                                            <Grid container spacing={2} mt={1}>
                                                <Grid item xs={6}>


                                                    <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                        <BusinessIcon sx={{ mr: 1 }} />
                                                        <Typography variant="body2"><strong>Agency : </strong> {sales?.agencies ? sales?.agencies[0]?.name : 'Private'}</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                        <AttachMoneyIcon sx={{ mr: 1 }} />
                                                        <Typography variant="body2"><strong>Price :</strong> {sales?.price?.display}</Typography>
                                                    </Box>

                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                        <CategoryIcon sx={{ mr: 1 }} />
                                                        <Typography variant="body2"><strong>Type :</strong>  {sales?.type}</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                        <EventIcon sx={{ mr: 1 }} />
                                                        <Typography variant="body2"><strong>Date :</strong> {sales?.date}</Typography>
                                                    </Box>
                                                </Grid>


                                            </Grid>
                                        )
                                    })}
                                    <div className='sale-card'>
                                    <Grid container spacing={2} mt={1}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }} className="card_new">
                                                <CategoryIcon sx={{ mr: 1 }} />
                                                <Typography variant="body2"><strong>Growth Since Purchase :</strong> {growth_since_purchase}%</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }} className="card_new">
                                                 <CategoryIcon sx={{ mr: 1 }} />
                                                <Typography variant="body2"><strong>Loan Amount :</strong> ${property.currentloanamount}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }} className="card_new">
                                                <CategoryIcon sx={{ mr: 1 }} />
                                                <Typography variant="body2"><strong>Loan to Value Ratio :</strong> {LoanToValueratio}%</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }} className="card_new">
                                                <CategoryIcon sx={{ mr: 1 }} />
                                                <Typography variant="body2"><strong>Borrowable Equity 80% LVR :</strong> ${borrowableEquities}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                <Typography variant="body2"><strong>Loan Amount :</strong> ${property.currentloanamount}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                <Typography variant="body2"><strong>Loan to Value Ratio :</strong> {LoanToValueratio}%</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid> */}
                                    {/* <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                <Typography variant="body2"><strong>Total Equity :</strong> ${toatlEquity}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                <Typography variant="body2"><strong>Borrowable Equity 80% LVR :</strong> ${borrowableEquities}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid> */}
                                    </div>
                                </Card>

                                {/* <Card
                                    sx={{
                                        padding: "20px !important",
                                        marginBottom: "30px !important",
                                        borderRadius: "15px !important",
                                        boxShadow: "none !important",
                                        backgroundColor: colors.primary[400],
                                    }}
                                >
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                <Typography variant="body2"><strong>Growth Since Purchase :</strong> {growth_since_purchase}%</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Card> */}

                                {/* <Card
                                    sx={{
                                        padding: "20px !important",
                                        marginBottom: "30px !important",
                                        borderRadius: "15px !important",
                                        boxShadow: "none !important",
                                        backgroundColor: colors.primary[400],
                                    }}
                                >
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                <Typography variant="body2"><strong>Loan Amount :</strong> ${property.currentloanamount}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                <Typography variant="body2"><strong>Loan to Value Ratio :</strong> {LoanToValueratio}%</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Card> */}

                                {/* <Card
                                    sx={{
                                        padding: "20px !important",
                                        marginBottom: "30px !important",
                                        borderRadius: "15px !important",
                                        boxShadow: "none !important",
                                        backgroundColor: colors.primary[400],
                                    }}
                                >

                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                <Typography variant="body2"><strong>Total Equity :</strong> ${toatlEquity}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }}>
                                                <Typography variant="body2"><strong>Borrowable Equity 80% LVR :</strong> ${borrowableEquities}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Card> */}

                                {/* <Card 
                                 sx={{
                                    padding: "20px !important",
                                    marginBottom: "30px !important",
                                    borderRadius: "15px !important",
                                    boxShadow: "none !important",
                                    backgroundColor: colors.primary[400],
                                }}
                            >
                                
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                            <Typography variant="body2"><strong>Income PA :</strong> $0</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                            <Typography variant="body2"><strong>Expenses PA :</strong> $35,247</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                            <Typography variant="body2"><strong>Cashflow PA :</strong> -$35,247</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center" p={2} sx={{ backgroundColor: colors.grey[900] }}>
                                            <Typography variant="body2"><strong>Cashflow After Depreciation PA :</strong> $0</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>   
                            </Card> */}

                                {/* <Card
                                sx={{
                                    padding: "20px !important",
                                    marginBottom: "30px !important",
                                    borderRadius: "15px !important",
                                    boxShadow: "none !important",
                                    backgroundColor: colors.primary[400],
                                }}
                            >

                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center" p={2} mb={2} sx={{ backgroundColor: colors.grey[900] }}>
                                            <Typography variant="body2"><strong>External Link :</strong>  </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Card> */}

                                <Card
                                    sx={{
                                        padding: "20px !important",
                                        marginBottom: "30px !important",
                                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
                                        backgroundColor: colors.primary[400],
                                        width: '100%',  // Ensure full width
                                    }}
                                >
                                    <Typography variant="h3" align="left" pb={3} zIndex={1} gutterBottom>
                                        Cash Flow Analysis
                                    </Typography>
                                    <Income />
                                    <PropertyDetailss
                                        propertyDetails={cashFlowAnalysis?.propertyDetails}
                                        selectedProperty={propertyDetails}
                                        hideCashFlow={true}
                                        agentProfile={''}
                                    />
                                    <AcquisitionCosts
                                        acquisitionCosts={cashFlowAnalysis.propertyDetails?.acquisitionCosts || null}
                                        propertyDetails={cashFlowAnalysis?.propertyDetails}
                                    />
                                    <AnnualHoldingCosts
                                        annualHoldingCosts={cashFlowAnalysis.propertyDetails?.annualHoldingCosts || null}
                                        updateAnnualHoldingCosts={(updates) => dispatch(updateAnnualHoldingCosts(updates))}
                                        propertyDetails={cashFlowAnalysis.propertyDetails}
                                    />
                                </Card>

                                {/* <Card>
                                    <Box mt={2}>\
                                        <iframe
                                            src={googleMapsUrl}
                                            width='100%'
                                            height="300"
                                            style={{ border: 0 }}
                                            allowFullScreen={true}
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                            aria-hidden="false"
                                            // tabIndex="0"
                                            title="property-map"
                                        ></iframe>\
                                    </Box>
                                </Card> */}

                            </Grid>

                        </Grid>
                        <Grid container spacing={2} columns={12} sx={{ width: '100%', margin: 0 }}>

                            <Grid item xs={12} md={12}>
                                {/* <Card
      sx={{
        padding: "20px 20px 0px 20px !important",
        marginBottom: "30px !important",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
        backgroundColor: colors.primary[400],
        width: '100%',  // Ensure full width
      }}
    >
      <Typography variant="h3" align="left" pb={3} zIndex={1} gutterBottom>
        Cash Flow Projection
      </Typography>

      <CashFlowProjection
        graphs={false}
        propertyDetails={cashFlowAnalysis.propertyDetails}
        keyAssumptions={cashFlowAnalysis.keyAssumptions}
        cashFlowProjection={cashFlowAnalysis.cashFlowProjection}
      />
    </Card> */}







                            </Grid>
                            {/* <Grid item xs={12} md={4}>
  
  </Grid> */}

                        </Grid>

                    </Box>

                )
            })}
        </>

    )
};

export default PropertyDetails;