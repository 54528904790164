import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { API, Auth } from "aws-amplify";
import {
  Container,
  Typography,
  Grid,
  Box,
  useTheme,
  Button,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
} from "@mui/material";
import { tokens } from "../../../theme";
import { toast } from "react-toastify";
import { errorMonitor } from "events";
import AuthenticatedAPI from "../../../auth_helper";
import { CloseFullscreen } from "@mui/icons-material";

interface InvestorFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  productId: string;
  promocode?: string;
}
interface BuyerAgentProfile {
  userid: string;
  name: string;
  email: string;
  logo_url: string;
  theme_color: string;
  capped_price: any;
}

interface Invites {
  userid: string;
  name: string;
  email: string;
  logo_url: string;
  theme_color: string;
  capped_price: any;
}

const AddInvestor: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [promotionCodeId, setpromotionCodeId] = useState();
  const { register, handleSubmit, getValues, setValue, formState: { errors } } =
    useForm<InvestorFormData>({ mode: "onChange", });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [profile, setProfile] = useState<BuyerAgentProfile | null>(null);
  const [userInvites, setUserInvites] = useState<Invites | any>();
  const [error, setError] = useState<string | null>(null);
  const [price, setPrice] = useState<number>(0.0);
  const [resfreshData, setRefreshData] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState<any>("");
  const stripe = useStripe();
  const elements = useElements();

  const values = getValues();
  const productId = values.productId;
  const promocode = values.promocode;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Handle pagination changes
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };
  const invitesArray = Array.isArray(userInvites) ? userInvites : [];
  // Data to display for current page
  const paginatedData =
    invitesArray?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ||
    [];

  useEffect(() => {
    fetchProfile();
  }, [resfreshData]);

  const fetchProfile = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user.username);
      const userId = user.attributes.sub; // Assuming the user ID is stored in the 'sub' attribute
      const name = user.attributes.given_name;
      const email = user.attributes.email;
      const response = await AuthenticatedAPI.request(
        "BuyersAgentsAPI",
        "get",
        `/agents/buyers-agent/${userId}`
      );

      const invitesresponse = await AuthenticatedAPI.request(
        "BuyersAgentsAPI",
        "get",
        `/agents/${userId}/invites`
      );
      setUserInvites(invitesresponse.invites);
      console.log(invitesresponse, "-------><><<><>");

      if (response && response) {
        setProfile({ ...response[0], name: name, email: email });
        console.log(profile, "-------><><<><>");
      } else {
        throw new Error("Failed to fetch buyer agent profile");
      }
    } catch (error) {
      console.error("Error fetching buyer agent profile:", error);
    }
  };

  const applyPromocode = async () => {
    const values = getValues();
    const productId = values.productId;
    const promocode = values.promocode;
    console.log(promocode, "--------;", productId);
    try {
      const promoResponse = await API.post(
        "BuyersAgentsAPI",
        "/agents/validate-promo-code",
        {
          body: { promocode: promocode, productId: productId }, // Replace with actual agent ID
        }
      );
      if (promoResponse.success) {
        await fetchPrice(productId, promocode);
        toast.success("Promo code applied succesfully!");
      } else {
        toast.error("Invalid Promocode!");
      }
      console.log("promoResponse", promoResponse);
      setpromotionCodeId(promoResponse.promotionCode);
    } catch (error) {
      console.log(error);
    }
  };

  // const onSubmit = async (data: InvestorFormData) => {
  //   setLoading(true);
  //   setError(null);

  //   try {
  //     // Create invite
  //     const inviteResponse = await API.post(
  //       "BuyersAgentsAPI",
  //       "/agents/create-invite",
  //       {
  //         body: {
  //           ...data,
  //           agentName: profile?.name,
  //           agentId: user,
  //           agentEmail: profile?.email,
  //           capped_price: profile?.capped_price,
  //           price: discountedPrice !== undefined && discountedPrice !== null ? discountedPrice : price

  //         }, // Replace with actual agent ID
  //       }
  //     );

  //     if (!profile?.capped_price) {
  //       if (!stripe || !elements) {
  //         throw new Error("Stripe not initialized");
  //       }

  //       const cardElement = elements.getElement(CardElement);
  //       if (!cardElement) {
  //         throw new Error("Card element not found");
  //       }

  //       // Confirm payment
  //       const { error: stripeError, paymentIntent } =
  //         await stripe.confirmCardPayment(inviteResponse.clientSecret, {
  //           payment_method: {
  //             card: cardElement,
  //             billing_details: {
  //               name: `${data.firstName} ${data.lastName}`,
  //               email: data.email,
  //             },
  //           },
  //         });

  //       if (stripeError) {
  //         console.error("Stripe Payment Error:", stripeError);
  //         throw new Error(
  //           `Payment confirmation failed: ${stripeError.message}`
  //         );
  //       }

  //       // Confirm invite and create investor account
  //       const response = await API.post(
  //         "BuyersAgentsAPI",
  //         "/agents/confirm-invite",
  //         {
  //           body: {
  //             inviteId: inviteResponse?.inviteId,
  //             paymentIntentId: paymentIntent?.id,
  //             capped_price: profile?.capped_price,
  //             ...data,
  //             agentName: profile?.name,
  //             agentId: user,
  //             agentEmail: profile?.email,
  //             price: discountedPrice != null ? discountedPrice : price
  //           },
  //         }
  //       );
  //     } else {
  //       const response = await API.post(
  //         "BuyersAgentsAPI",
  //         "/agents/confirm-invite",
  //         {
  //           body: {
  //             inviteId: inviteResponse.inviteId,
  //             paymentIntentId: "",
  //             capped_price: profile?.capped_price,
  //           },
  //         }
  //       );
  //     }

  //     // Check if the API call was successful
  //     // if (!response.status==200) {
  //     //   // const errorText = await response.text();
  //     //   console.error('API Error:', response);
  //     //   throw new Error(`API call failed: ${response.status} ${response.statusText}`);
  //     // }

  //     toast.success("Investor added successfully!");
  //     setRefreshData(!resfreshData);
  //     // Show success message
  //   } catch (err: unknown) {
  //     setError((err as Error).message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // This is not a good design I have pricing table that I want to use look at pages/pricing.tsx
  // and use it here https://docs.stripe.com/payments/checkout/pricing-table







  const onSubmit = async (data: InvestorFormData) => {
    setLoading(true);
    setError(null);
  
    try {
      // Determine the price to send
      let priceToSend = discountedPrice !== undefined && discountedPrice !== null ? discountedPrice : price;
      let cappedPriceToSend = profile?.capped_price;
  
      // If discountedPrice is 0 and not null, use capped price logic but send capped_price as false
      if (discountedPrice === 0 && discountedPrice !== null) {
        cappedPriceToSend = false;  // Set capped_price as false in this case
      }
  
      // Create invite
      const inviteResponse = await API.post(
        "BuyersAgentsAPI",
        "/agents/create-invite",
        {
          body: {
            ...data,
            agentName: profile?.name,
            agentId: user,
            agentEmail: profile?.email,
            capped_price: cappedPriceToSend,
            price: priceToSend,
          },
        }
      );
  
      // Handle Stripe payment if capped_price is not present
      if (!profile?.capped_price && priceToSend !== 0) {
        if (!stripe || !elements) {
          throw new Error("Stripe not initialized");
        }
  
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
          throw new Error("Card element not found");
        }
  
        // Confirm payment
        const { error: stripeError, paymentIntent } =
          await stripe.confirmCardPayment(inviteResponse.clientSecret, {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: `${data.firstName} ${data.lastName}`,
                email: data.email,
              },
            },
          });
  
        if (stripeError) {
          console.error("Stripe Payment Error:", stripeError);
          throw new Error(
            `Payment confirmation failed: ${stripeError.message}`
          );
        }
  
        // Confirm invite and create investor account
        const response = await API.post(
          "BuyersAgentsAPI",
          "/agents/confirm-invite",
          {
            body: {
              inviteId: inviteResponse?.inviteId,
              paymentIntentId: paymentIntent?.id,
              capped_price: cappedPriceToSend,
              ...data,
              agentName: profile?.name,
              agentId: user,
              agentEmail: profile?.email,
              price: priceToSend
            },
          }
        );
      } else {
        const response = await API.post(
          "BuyersAgentsAPI",
          "/agents/confirm-invite",
          {
            body: {
              inviteId: inviteResponse.inviteId,
              paymentIntentId: "",
              capped_price: cappedPriceToSend,
              agentName: profile?.name,
              agentId: user,
              price: priceToSend,
              agentEmail: profile?.email,
              ...data,
            },
          }
        );
      }
  
      toast.success("Investor added successfully!");
      setRefreshData(!resfreshData);
  
    } catch (err: unknown) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };
  












  const fetchPrice = async (productId: any, promoCode: any) => {
    try {
      const response = await API.post("BuyersAgentsAPI", "/agents/get-price", {
        body: {
          productId: productId,
          promocode: promoCode,
        },
      });

      console.log(response);
      setPrice(response.originalPrice);

      if (promoCode) {

        setDiscountedPrice(response.finalPrice);
      } else {

        setDiscountedPrice(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle product selection change
  const handleProductChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedProductId = event.target.value;
    setValue("productId", selectedProductId);
    await fetchPrice(selectedProductId, "");
    // if (stripe) {
    //   const prices = await stripe.products.retrieve(selectedProductId);
    //   const price = await stripe.prices.retrieve(prices.default_price as string);
    //   setPrice(price.unit_amount ? price.unit_amount / 100 : 0); // Convert cents to dollars
    //   setDiscountedPrice(null); // Reset discounted price when product changes
    // }
  };
  return (
    <div>
      <Container>
        <Box
          className="add_investorCard my-12 xl:my-20"
          p={isMobile ? 5 : 7}
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? "#f2f0f0" : "#1F2A40",
            borderRadius: "20px",
            overflow: "hidden",
          }}
        >
          <Box className="investorCard_inner">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h1"
                  gutterBottom
                  sx={{
                    fontSize: "36px",
                    fontWeight: "700",
                    color: colors.grey[100],
                  }}
                  mb={3}
                  textAlign={"center"}
                >
                  Add <span className="text-[#00bda2]">Investor</span>
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul>
                    <li>
                      <label className="text-base font-semibold text-[#00bda2]">
                        First Name
                      </label>
                      <input
                        {...register("firstName")}
                        placeholder="First Name"
                      />
                    </li>
                    <li>
                      <label className="text-base font-semibold text-[#00bda2]">
                        Last Name
                      </label>
                      <input
                        {...register("lastName")}
                        placeholder="Last Name"
                      />
                    </li>
                    <li>
                      <label className="text-base font-semibold text-[#00bda2]">
                        Email Address
                      </label>
                      <input
                        {...register("email", { pattern: /^\S+@\S+$/i })}
                        placeholder="Email"
                      />
                    </li>
                    <li>
                      <label className="text-base font-semibold text-[#00bda2]">
                        Phone Number
                      </label>
                      <input
                        {...register("phoneNumber", {
                          required: "Phone number is required",
                          pattern: {
                            value: /^\+61[0-9]{9}$/,
                            message: "Please enter a valid Australian phone number",
                          },
                        })}
                        placeholder="+61 XXXXXXXXX"
                      />
                      {errors.phoneNumber && (
                        <Typography variant="body2" color="error">
                          {errors.phoneNumber.message}
                        </Typography>
                      )}
                    </li>
                    {!profile?.capped_price && (
                      <>
                        <li>
                          <label className="text-base font-semibold text-[#00bda2]">
                            Product
                          </label>
                          <select
                            {...register("productId")}
                            onChange={handleProductChange}
                          >
                            <option value="">Select a product</option>
                            <option value="prod_QogFYgZkpufF1b">testt11</option>
                            <option value="prod_QUkPozTmjG7SY1">
                              Self Investar
                            </option>
                            <option value="prod_QQjqoAfwhvCjCU">
                              Investar Launch Edition - Strategy Session
                            </option>
                          </select>
                        </li>
                      </>
                    )}
                  </ul>

                  {!profile?.capped_price && (
                    <div className="strip_container mb-6">
                      <CardElement />
                    </div>
                  )}

                  <Grid container spacing={0} className="items-start">
                    <Grid item xs={12}>
                      <label className="text-base font-semibold text-[#00bda2]">
                        Promo Code
                      </label>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className="flex gap-6 items-center"
                    >
                      <input
                        {...register("promocode")}
                        placeholder="Enter Promocode"
                        id="promocode"
                        className="w-full"
                      />
                      <Button
                        onClick={applyPromocode}
                        type="button"
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        sx={{
                          borderRadius: "10px",
                          fontSize: "16px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          color: "#ffffff",
                          padding: "9px 40px",
                          marginTop: "5px",
                          // backgroundColor: theme.palette.mode === "light" ? "#1F2A40" : "#6c63ff",
                        }}
                      >
                        Apply
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {/* <Typography
                        variant="h3"
                        gutterBottom
                        sx={{
                          fontSize: "26px",
                          fontWeight: "700",
                          color: colors.grey[100],
                          marginBottom: "0px",
                        }}
                        textAlign={"center"}
                      >
                        ${price}
                        <br/>
                        {`- $${price-discountedPrice}`}
                        <br/>
                        {discountedPrice&& `New total price $${discountedPrice}`}
                      </Typography> */}

                      <div className="ps-10">
                        <div className="flex items-center justify-between gap-5 mt-3">
                          <Typography
                            component="span"
                            sx={{
                              fontSize: "22px", // Increase font size for the original price
                              fontWeight: "600",
                              color: colors.grey[100], // Use primary color for emphasis
                            }}
                          >
                            Subtotal
                          </Typography>

                          <Typography
                            variant="h3"
                            gutterBottom
                            sx={{
                              fontSize: "28px",
                              fontWeight: "bold",
                              color: colors.grey[100],
                              marginBottom: "0px",
                              textAlign: "center",
                            }}
                          >
                            ${price}
                          </Typography>
                        </div>
                        <div className="flex items-center justify-between gap-5 mt-3 pb-3 border-b border-[#cccccc]">
                          <Typography
                            component="span"
                            sx={{
                              fontSize: "18px",
                              fontWeight: "600",
                              color: colors.grey[500],
                            }}
                          >
                            Discount
                          </Typography>
                          {discountedPrice !== null ? (
                            <Typography
                              component="span"
                              sx={{
                                color: colors.redAccent[500],
                                fontSize: "18px",
                                fontWeight: "600",
                                marginLeft: "0px",
                              }}
                            >
                              {`-$${Math.abs(price - discountedPrice)}`}
                            </Typography>

                          ) : (
                            <Typography
                              variant="h3"
                              gutterBottom
                              sx={{
                                fontSize: "28px",
                                fontWeight: "bold",
                                color: colors.grey[100],
                                marginBottom: "0px",
                                textAlign: "center",
                              }}
                            >
                              $0
                            </Typography>
                          )}
                        </div>
                        <div className="flex items-center justify-between gap-5 mt-0">
                          <Typography
                            component="span"
                            sx={{
                              fontSize: "22px",
                              fontWeight: "700",
                              color: colors.grey[100],
                              marginTop: "8px", // Adds spacing above the new price
                              display: "block",
                            }}
                          >
                            Total price:
                          </Typography>

                          {discountedPrice !== null ? (
                            <Typography
                              component="span"
                              sx={{
                                fontSize: "30px",
                                fontWeight: "700",
                                color: colors.greenAccent[400],
                                marginTop: "8px", // Adds spacing above the new price
                                display: "block",
                              }}
                            >
                              ${discountedPrice}
                            </Typography>
                          ) : (
                            <Typography
                              component="span"
                              sx={{
                                fontSize: "30px",
                                fontWeight: "700",
                                color: colors.greenAccent[400],
                                marginTop: "8px", // Adds spacing above the new price
                                display: "block",
                              }}
                            >
                              ${price}
                            </Typography>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"

                    disabled={loading || !!errors?.phoneNumber} // Convert to boolean
                    sx={{
                      borderRadius: "10px",
                      fontSize: "16px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      color: "#ffffff",
                      padding: "10px 40px",
                      marginTop: "30px",
                      // backgroundColor: theme.palette.mode === "light" ? "#1F2A40" : "#6c63ff",
                    }}
                  >
                    {loading ? "Processing..." : "Add Investor"}
                  </Button>
                </form>
                {error && error != undefined && (
                  <p style={{ color: "red" }}>{error}</p>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          className="add_investorCard my-12 xl:my-20"
          p={isMobile ? 5 : 7}
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? "#f2f0f0" : "#1F2A40",
            borderRadius: "20px",
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontSize: "36px",
              fontWeight: "700",
              color: colors.grey[100],
            }}
            mb={3}
            textAlign={"center"}
          >
            Investar <span className="text-[#00bda2]">Invites</span>
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>S.no</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData?.map((row: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.first_name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "5px",
                          fontSize: "14px",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          //  color: "#5BC0BE",
                          color:
                            row.invite_status === "Confirmed"
                              ? "#4CAF50"
                              : row.invite_status === "pending"
                                ? "#FF5733"
                                : "#5BC0BE",
                          backgroundColor: "rgb(91 192 190 / 10%)",
                          padding: "5px 20px",
                          boxShadow: "none !important",
                        }}
                      >
                        {row.invite_status}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={userInvites?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Container>


    </div>
  );
};

export default AddInvestor;
