import React, { useEffect, useCallback, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Typography,
  useTheme,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../store";

import { InvestedProperty, InvestNetScenario, ScenarioProperty } from "../../../types/BuySell";
import { tokens } from "../../../theme";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Result,
  useFetchpropertDetailed,
  useFetchProperties,
  useFetchpropertyavm,
} from "../../../hooks/properties/properties";
import Autocomplete from "@mui/material/Autocomplete";
import { Field, Formik, useFormikContext } from "formik";
import CheckIcon from "@mui/icons-material/Check";
import {
  CurrentPortFolio,
  CurrentPortfolioInfo,
  Property,
} from "../../../types/User";
import * as yup from "yup";
import InfoIcon from "@mui/icons-material/Info";
import { calculateBorrowableEquity } from "../../../utils/finance_formulas";
import { updateProperty, removeProperty, updateScenario } from "../../../features/scenarios";

interface PropertyColumnProps {
  index: number;
  scenario: InvestNetScenario;
  isNewProperty: boolean;
  // defaultProperty?:any
}

const InvestmentPropertiesSchema = yup.object({
  investmentProperties: yup.array().of(
    yup.object({
      // purchaseYear: yup.number().required("Purchase year is required").min(1900, "Invalid year").max(new Date().getFullYear(), "Invalid year"),
      deposit: yup
        .number()
        .required("Deposit is required")
        .min(0, "Invalid deposit amount"),
    })
  ),
});

const InvestedPropertyColumn: React.FC<PropertyColumnProps> = ({
  index,
  scenario,
  isNewProperty
  // defaultProperty
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch: AppDispatch = useDispatch();
  // const propertyyData = useSelector(
  //   (state: RootState) => state.investedProperties.properties[index]
  // );

  const propertyyData = scenario?.properties[index]
  console.log("propertyData", scenario)
  // console.log(propertyyData,'------------>>>>>>>>',defaultProperty,'----------,',index)
  // console.log(scenario.properties[index],'-----------',index,'------------',defaultProperty[index])

  const [addres, setAddress] = useState("");
  const { propertyData, propertyLoading, error } = useFetchProperties(addres);
  const [propertyID, setPropertyID] = useState("");
  const [selectedAddresses, setSelectedAddresses] = useState<string[]>([]);
  const [propertyIDS, setPropertyIDS] = useState<string[]>([]);
  const [currentEstimatedValue, setCurrentEstimatedValue] = useState<string[]>(
    []
  );
  const [isAutocompleteActive, setIsAutocompleteActive] = useState<
    number | null
  >(null);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: number]: boolean;
  }>({});
  const [propertyDetailed, setPropertyDetails] = useState<any[]>([]);
  const { propertyavmdata, propertyavmloading, propertyavmerror } =
    useFetchpropertyavm(propertyID);
  const {
    propertyDetaileddata,
    propertyDetailedloading,
    propertyDetailederror,
  } = useFetchpropertDetailed(propertyID);
  const [investmentProperties, setInvestmentProperties] = useState<
    CurrentPortFolio[]
  >([]);
  const toggleAutocomplete = (index: number) => {
    if (isAutocompleteActive !== index && selectedAddresses) {
      setIsAutocompleteActive((prev) => (prev === index ? null : index));
    }
  };

  const HandleInput = (value: string) => {
    setAddress(value);
  };

  const handleSelect = (event: any, value: Result | null, index: number) => {
    if (value) {
      const newSelectedAddress = `${value.match.address.streetAddress} ${value.match.address.locality} ${value.match.address.state} ${value.match.address.postcode}`;

      setSelectedAddresses((prevAddresses) => {
        const newAddresses = [...prevAddresses];
        if (index !== null && newAddresses[index] !== undefined) {
          newAddresses[index] = newSelectedAddress;
        } else {
          newAddresses.push(newSelectedAddress);
        }
        return newAddresses;
      });

      setSelectedAddress(newSelectedAddress);
      setPropertyID(value.match.id);

      setPropertyIDS((prevID) => {
        const newPropertyIDS = [...prevID];
        if (index !== null && newPropertyIDS[index] !== undefined) {
          newPropertyIDS[index] = value.match.id;
        } else {
          newPropertyIDS.push(value.match.id);
        }
        return newPropertyIDS;
      });

      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        [index]: true,
      }));
    }
  };

  const addEstimatedValue = async (currentValues: string) => {
    setCurrentEstimatedValue((prevValues) => {
      if (isAutocompleteActive !== null) {
        if (prevValues[isAutocompleteActive] !== undefined) {
          const newValues = [...prevValues];
          newValues[isAutocompleteActive] = currentValues;
          return newValues;
        }
      }
      if (!prevValues.includes(currentValues)) {
        return [...prevValues, currentValues];
      }
      return prevValues;
    });
  };

  const addPropertyDetailedValue = (currentValue: any) => {
    setPropertyDetails((prevValues) => {
      if (
        isAutocompleteActive !== null &&
        prevValues[isAutocompleteActive] !== undefined
      ) {
        const newValues = [...prevValues];
        newValues[isAutocompleteActive] = currentValue;
        return newValues;
      }
      if (
        !prevValues.some((detail) => detail && detail.id === currentValue.id)
      ) {
        return [...prevValues, currentValue];
      }
      return prevValues;
    });
  };

  const handleChange = (
    event:
      | React.ChangeEvent<{ value: number }>
      | React.ChangeEvent<HTMLInputElement>,
    field: keyof ScenarioProperty, // Assuming InvestedProperty is the shape of each property object
    index: number // Pass the index to specify which property to update
  ) => {
    let value
    // event.target instanceof HTMLInputElement && event.target.type === "number"
    //   ? Number(event.target.value)
    //   : event.target.value;

    // const updatedProperty = {
    //   ...propertyyData, // Spread the existing property data
    //   [field]: value // Update only the specified field
    // };
    if (field === "ispporproperty") {
      value = (event.target as HTMLInputElement).checked; // Checkbox value
    } else {
      value =
        event.target instanceof HTMLInputElement && event.target.type === "number"
          ? Number(event.target.value)
          : event.target.value;
    }
    dispatch(
      updateProperty({
        updatedProperty: { ...propertyyData }, // Spread the existing property data

        index: index,
        field: field,
        value: value
      }) // Include the index to identify which property to update

    );
    if (field === 'deposit') {
      const value: number = Number(event.target.value);
      // setTimeout(() => {
      const leverageRatio =
        ((parseFloat(propertyyData?.value) * 1.06 - (value as number)) /
          parseFloat(propertyyData?.value)) *
        100;

      dispatch(
        updateProperty({
          index: index, // Specify the index of the property to update
          field: 'leverageRatio' as keyof ScenarioProperty, // Ensure this field is a key of ScenarioProperty
          value: leverageRatio, // The new net yield value
          updatedProperty: { ...propertyyData, leverageRatio } // Update the full property object
        })
      );
      // }, 0)
    }
    // dispatch(updateScenario({
    //   ...scenario,
    //   scenarioName: scenario.scenario_name,
    //   properties: scenario.properties.map((property, i) => {
    //     if (i === index) {
    //       return { ...property, [field]: value };
    //     }
    //     return property;
    //   })
    // }));
  };

  // const handleChange = (
  //   event:
  //     | React.ChangeEvent<{ value: number }>
  //     | React.ChangeEvent<HTMLInputElement>,
  //   field: keyof InvestedProperty
  // ) => {
  //   const value =
  //     event.target instanceof HTMLInputElement && event.target.type === "number"
  //       ? Number(event.target.value)
  //       : event.target.value;

  //   dispatch(
  //     updateProperty({ ...propertyyData, [field]: value })
  //   );
  // };

  const calculateLeverageRatio = useCallback(() => {
    if (propertyyData?.value && propertyyData?.deposit) {
      const leverageRatio =
        ((parseFloat(propertyyData?.value) * 1.06 - propertyyData?.deposit) /
          parseFloat(propertyyData?.value)) *
        100;

      return leverageRatio.toFixed(2);
    }
    return "";
  }, [propertyyData?.value, propertyyData?.deposit]);

  // const borrowableEquities = calculateBorrowableEquity(scenario.properties[index]);
  const borrowableEquities = isNewProperty
    ? 0
    : calculateBorrowableEquity(propertyyData);
  console.log("propertyyData", propertyyData)

  console.log("borrowableEquities", borrowableEquities)

  // useEffect(() => {
  //   const leverageRatio = parseFloat(calculateLeverageRatio());
  //   if (!isNaN(leverageRatio)) {
  //     dispatch(updateProperty({ index, data: { leverageRatio } }));
  //   }
  // }, [
  //   propertyyData?.value,
  //   propertyyData?.deposit,
  //   dispatch,
  //   index,
  //   calculateLeverageRatio,
  // ]);
  const currentYear = new Date().getFullYear();
  // const years = Array?.from(new Array(41), (val, index) => currentYear + index);
  const years = Array.from(new Array(2050 - 1990 + 1), (val, index) => 1990 + index);

  const handleRemove = (index: number) => {
    dispatch(removeProperty(index));
  };

  // const propertyName = Array.isArray(propertyyData)
  // && propertyyData.map((address: any) => 
  //     address?.address?.split(" ").slice(0, 4).join(" ")
  //   )
  const propertyName = Array.isArray(propertyyData)
    ? propertyyData.map((address: any): string =>
      address?.address?.split(" ").slice(0, 4).join(" ")
    )
    : [];
  console.log('prope----------------------rtyName', propertyyData, investmentProperties)

  return (
    <Formik
      initialValues={{
        address: propertyyData?.address || "",
        purchaseYear: propertyyData?.purchaseYear || 0,
        deposit: propertyyData?.deposit || 0,
      }}
      validationSchema={InvestmentPropertiesSchema}
      onSubmit={(values, { setSubmitting }) => { }}
    >
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>

        <Box
          padding={2}
          boxShadow={3}
          gap={2}
          borderRadius={2}
          sx={{ backgroundColor: colors.primary[400] }}
        >
          <Grid sm={12} >
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                marginBottom: "0px",
              }}
            >
              {propertyyData?.ispporproperty ? (
                <Typography
                  sx={{ padding: "6px", backgroundColor: "#4cceac", borderRadius: "3px", fontSize: "12px", width: "60px", textAlign: "center" }}>PPOR</Typography>) : (

                <Typography></Typography>
              )}
              <IconButton size="large" onClick={() => handleRemove(index)} sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "end",
                marginBottom: "0px",
              }}>


                <DeleteIcon sx={{
                  color: "red"
                }} />
              </IconButton>

            </Box>

            {/* <Box
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "end",
                marginBottom: "0px",
              }}
            >
              <IconButton size="large" onClick={handleRemove}>
                <DeleteIcon sx={{ color: "red" }} />
              </IconButton>
            </Box> */}
          </Grid>

          <Box display="flex" flexDirection="column" gap={2}>
            {!isNewProperty && propertyyData?.address ? (
              <Typography
                variant="h6"
                gutterBottom
                mb={0}
                sx={{ height: "56px", padding: "5px 10px", lineHeight: "1.5", overflowY: "auto" }}
              >
                {propertyyData.address}
              </Typography>
            ) : (
              <>
                <TextField
                  label="Property Name"
                  variant="outlined"
                  type="text"
                  value={propertyyData?.address || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, "address", index)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  placeholder="Enter property name"
                  sx={{
                    "& .MuiInputBase-input-focused": {
                      color: colors.grey[100],
                      borderColor: colors.grey[100],
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: colors.grey[100],
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: colors.grey[100], // Adjust as needed
                    },
                  }}
                />
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox

                      // name={`investmentProperties[${index}].ispporproperty`}
                      checked={propertyyData?.ispporproperty || false}// Ensure this checks the correct state
                      // onChange={() => handleCheckboxChange(index, values, setFieldValue)}
                      // disabled={values.investmentProperties.some((p, i) => p.ispporproperty && i !== index)} // Ensure proper condition
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(e, "ispporproperty", index) // Pass the correct field and index
                      }
                      disabled={
                        !propertyyData?.ispporproperty && // Disable if not currently selected
                        scenario.properties.some((prop) => prop.ispporproperty) // Another checkbox is selected
                      }
                      sx={{
                        color: investmentProperties && investmentProperties[index] ? colors.grey[100] : 'inherit',
                        "&.Mui-checked": {
                          color: colors.grey[100],
                        },
                      }}
                    />
                  }
                  label="Is this your PPOR Property?"
                  sx={{
                    color: investmentProperties && investmentProperties[index] ? colors.grey[100] : 'inherit',
                  }}
                />
              </>
            )}




            <TextField
              label="Initial Property Value"
              variant="outlined"
              type="number"
              value={propertyyData?.value || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, "value", index)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              placeholder="Enter property value"
              sx={{
                "& .MuiInputBase-input-focused": {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: colors.grey[100],
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
            {/* {propertyName[index] ? ( */}
            {/* {propertyyData?.address ? (
              <TextField
                // select
                label="Property Purchase Year"
                variant="outlined"
                value={propertyyData?.purchaseYear || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, "purchaseYear", index)
                }
                placeholder="Select purchase year"
                sx={{
                  "& .MuiInputBase-input-focused": {
                    color: colors.grey[100],
                    borderColor: colors.grey[100],
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: colors.grey[100],
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: colors.grey[100], // Adjust as needed
                  },
                }}
              />
            ) : ( */}
            <Field name={`purchaseYear`}>
              {({ field }: any) => (
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Purchase Year</InputLabel>
                  <Select
                    {...field}
                    label="Purchase Year"
                    onChange={(e: any) => handleChange(e, "purchaseYear", index)}
                    value={propertyyData?.purchaseYear || ""}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Field>
            {/* )} */}
            <TextField
              label="Deposit"
              variant="outlined"
              name={"deposit"}
              // required={true}
              type="number"
              value={propertyyData?.deposit || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, "deposit", index)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              placeholder="Enter deposit amount"
              sx={{
                "& .MuiInputBase-input-focused": {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: colors.grey[100],
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
            {/* {depositError && !propertyyData?.deposit && <span style={{color:'red'}}>{depositError}</span>} */}
            <TextField
              label="Borrowable Equity"
              variant="outlined"
              type="number"
              value={Math.round(borrowableEquities) || "0"}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputBase-input-focused": {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: colors.grey[100],
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
            <TextField
              label="Equity Accessed"
              variant="outlined"
              type="number"
              value={propertyyData?.equityAccessed || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, "equityAccessed", index)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              placeholder="Enter equity accessed"
              sx={{
                "& .MuiInputBase-input-focused": {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: colors.grey[100],
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
            {/* <TextField
              label="Is PPOR"
              variant="outlined"
              type="text"
              value={propertyyData?.ispporproperty ? 'True' : 'False' || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, "equityAccessed")
              }

              placeholder="Is PPOR property"
              sx={{
                "& .MuiInputBase-input-focused": {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: colors.grey[100],
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            /> */}
            <TextField
              label={
                <Box display="flex" alignItems="center">
                  Leverage Ratio When Purchased
                  <Tooltip title="Leverage Ratio includes stamp duty and other expenses.">
                    <InfoIcon sx={{ ml: 1 }} />
                  </Tooltip>
                </Box>
              }
              // label="Leverage Ratio When Purchased"
              variant="outlined"
              InputProps={{
                readOnly: true,
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              value={calculateLeverageRatio()}
              sx={{
                "& .MuiInputBase-input-focused": {
                  color: colors.grey[100],
                  borderColor: colors.grey[100],
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: colors.grey[100],
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: colors.grey[100], // Adjust as needed
                },
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Formik>
  );
};

export default InvestedPropertyColumn;
