import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Paper,
  useTheme,
  Drawer,
  Fab,
  Tooltip,
  Badge,
  LinearProgress,
  Divider,
  Chip,
  IconButton,
  Stack,
  Alert,
  AlertTitle,
  Tabs,
  Tab
} from '@mui/material';
import {
  SmartToy,
  TrendingUp,
  Warning,
  AutoAwesome,
  Refresh,
  Assignment,
  CompareArrows,
  Close,
  AccountBalance,
  Timeline,
  ShowChart,
  PriceCheck,
  Insights,
  BarChart,
  Gavel,
  Lightbulb,
  LocationOn,
  AttachMoney,
  Payments,
  InfoOutlined,
  CheckCircle,
  ArrowForward,
  ArrowUpward,
  ArrowDownward,
  Savings,
  Receipt
} from '@mui/icons-material';
import AuthenticatedAPI from '../../../../auth_helper';
import { keyframes } from '@mui/system';
import { APIResponse } from '../../../../types/CashFlow';
import { calculateTaxWithDetails } from "../../../../hooks/properties/properties";

// Reuse the same animations
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

interface CashFlowAIAnalysisProps {
  isAnalysisReady: boolean;
  scenarioName?: string;
}

interface CashFlowYear {
  year: number;
  propertyValue: number;
  loanAmount: number;
  equity: number;
  grossRent: number;
  rentalExpenses: number;
  beforeTaxCashFlow: number;
  afterTaxCashFlow: number;
  costIncomePw: number;
  PrincipalInterestRepayment: number;
  InterestOnlyRepayment: number;
  InterestCharged: number;
  InterestRate: number;
  ExtraPrincipalRepayment: number;
}

interface FullTermAnalysisYear {
  year: number;
  payment: number;
  interestPaid: number;
  principalPaid: number;
  remainingLoan: number;
  interestRate: number;
  afterTaxCashFlow: number;
  propertyValue: number;
  equity: number;
  grossRent: number;
  rentalExpenses: number;
  beforeTaxCashFlow: number;
}

interface YearlyProjection {
  year: number;
  propertyValue: number;
  loanAmount: number;
  equity: number;
  grossRent: number;
  rentalExpenses: number;
  beforeTaxCashFlow: number;
  afterTaxCashFlow: number;
  costIncomePw: number;
  payment: number;
  interestPaid: number;
  principalPaid: number;
  remainingLoan: number;
  interestRate: number;
  extraPrincipalRepayment: number;
}

interface PayloadType {
  propertyDetails: any; // Using any for now as the type is complex
  cashFlowMetrics: any;
  expenses: any;
  projections: {
    capitalGrowthRate: number | undefined;
    rentalGrowthRate: number | undefined;
    inflationRate: number | undefined;
    vacancyRate: number | undefined;
    yearlyProjections: {
      principalAndInterest: YearlyProjection[];
      interestOnly: YearlyProjection[];
    };
    cumulativeProjections: any[];
  };
  employmentIncome: any;
  taxAnalysis: any;
  loanAnalysis: {
    principalAndInterest: Array<{
      year: number;
      payment: number;
      interestPaid: number;
      principalPaid: number;
      remainingLoan: number;
      interestRate: number;
    }>;
    interestOnly: Array<{
      year: number;
      payment: number;
      interestPaid: number;
      principalPaid: number;
      remainingLoan: number;
      interestRate: number;
    }>;
  };
  breakEvenAnalysis: {
    principalAndInterest: {
      year: number;
      monthlyPositive: number;
    };
    interestOnly: {
      year: number;
      monthlyPositive: number;
    };
  };
}

interface AIAnalysisResponse {
  cashFlowHealth: any[];
  loanTermAnalysis: any[];
  riskAssessment: any[];
  loanMetrics: {
    principalAndInterest: any;
    interestOnly: any;
  };
  fullTermAnalysis: {
    principalAndInterest: FullTermAnalysisYear[];
    interestOnly: FullTermAnalysisYear[];
  };
  aiSummary: {
    strengths: string[];
    watchPoints: string[];
    recommendations: string[];
  };
  propertyOverview: {
    address: string;
    propertyValue: number;
    weeklyRent: number;
    annualRent: number;
    lvr: number;
    state: string;
  };
}

const CashFlowAIAnalysis: React.FC<CashFlowAIAnalysisProps> = ({
  isAnalysisReady = false,
  scenarioName = 'Default Scenario'
}) => {
  const theme = useTheme();
  const [analysis, setAnalysis] = useState<AIAnalysisResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [timelineTab, setTimelineTab] = useState<'IO' | 'P&I'>('IO');

  // Get latest state from Redux
  const latestCashFlowAnalysis = useSelector((state: any) => state.cashFlowAnalysis);

  const fetchAnalysis = async () => {
    try {
      setLoading(true);
      setError(null);

      // Get fresh data from Redux at the time of the API call
      const freshState = latestCashFlowAnalysis;

      // Validate loan details first
      const hasValidLoanDetails = 
        typeof freshState.propertyDetails?.interestRate === 'number' && 
        freshState.propertyDetails?.interestRate > 0 && 
        typeof freshState.propertyDetails?.loanTerm === 'number' && 
        freshState.propertyDetails?.loanTerm > 0;

      if (!hasValidLoanDetails) {
        throw new Error('Please set both interest rate and loan term before proceeding with analysis');
      }

      // Validate required property details
      const requiredFields = [
        freshState.propertyDetails?.address,
        freshState.propertyDetails?.propertyValue,
        freshState.propertyDetails?.estimatedWeeklyRent,
        freshState.propertyDetails?.loanAmount,
        freshState.propertyDetails?.lvr
      ];

      const hasValidPropertyDetails = requiredFields.every(field => 
        field !== undefined && 
        field !== null && 
        field !== '' && 
        field !== 0
      );

      if (!hasValidPropertyDetails) {
        throw new Error('Please fill in all required property details');
      }

      // Get the latest cash flow projection data
      const cashFlowProjection = freshState.cashFlowProjection;
      if (!cashFlowProjection) {
        throw new Error('Cash flow projection data is not available');
      }

      // Extract scenario data
      const scenario1Data = cashFlowProjection.scenario1?.cashFlowYears || [];
      const scenario2Data = cashFlowProjection.scenario2?.cashFlowYears || [];

      // Prepare loan analysis data from scenario data
      const loanAnalysis = {
        principalAndInterest: scenario1Data.map((year: CashFlowYear) => ({
          year: year.year,
          payment: year.PrincipalInterestRepayment,
          interestPaid: year.InterestCharged,
          principalPaid: year.PrincipalInterestRepayment - year.InterestCharged,
          remainingLoan: year.loanAmount,
          interestRate: year.InterestRate
        })),
        interestOnly: scenario2Data.map((year: CashFlowYear) => ({
          year: year.year,
          payment: year.InterestOnlyRepayment,
          interestPaid: year.InterestCharged,
          principalPaid: 0,
          remainingLoan: year.loanAmount,
          interestRate: year.InterestRate
        }))
      };

      // Calculate break-even details for the payload
      const calculateBreakEvenDetailsForPayload = (scenario: 'P&I' | 'IO') => {
        if (!freshState.projections?.yearlyProjections) return { year: 0, monthlyPositive: 0 };
        
        const yearlyData = scenario === 'P&I' 
          ? freshState.projections.yearlyProjections.principalAndInterest
          : freshState.projections.yearlyProjections.interestOnly;
        
        if (!yearlyData?.length) return { year: 0, monthlyPositive: 0 };
        
        let cumulativeCashFlow = 0;
        let breakEvenYear = 0;
        let monthlyPositive = 0;

        for (const yearData of yearlyData) {
          cumulativeCashFlow += yearData.afterTaxCashFlow;
          
          if (cumulativeCashFlow > 0 && breakEvenYear === 0) {
            breakEvenYear = yearData.year;
            monthlyPositive = yearData.afterTaxCashFlow / 12;
            break;
          }
        }

        return { year: breakEvenYear, monthlyPositive };
      };

      // Prepare payload using existing Redux data
      const payload: PayloadType = {
        propertyDetails: {
          ...freshState.propertyDetails,
          address: freshState.propertyDetails?.address,
          state: freshState.propertyDetails?.state,
          propertyValue: Number(freshState.propertyDetails?.propertyValue),
          purchasePrice: Number(freshState.propertyDetails?.propertyValue),
          loanAmount: Number(freshState.propertyDetails?.loanAmount),
          lvr: Number(freshState.propertyDetails?.lvr),
          interestRate: Number(freshState.propertyDetails?.interestRate),
          previousInterestRate: Number(freshState.propertyDetails?.previousInterestRate),
          loanTerm: Number(freshState.propertyDetails?.loanTerm),
          repaymentType: freshState.propertyDetails?.repaymentType,
          interestOnly: Number(freshState.propertyDetails?.interestOnly),
          deposit: Number(freshState.propertyDetails?.deposit),
          legals: Number(freshState.propertyDetails?.legals),
          insurance: Number(freshState.propertyDetails?.insurance),
          buildInspection: Number(freshState.propertyDetails?.buildInspection),
          pestInspection: Number(freshState.propertyDetails?.pestInspection),
          otherCosts: Number(freshState.propertyDetails?.otherCosts),
          estimatedWeeklyRent: Number(freshState.propertyDetails?.estimatedWeeklyRent),
          grossYield: freshState.propertyDetails?.grossYield,
          stampDuty: freshState.propertyDetails?.stampDuty,
          annualHoldingCosts: {
            propertyManagementFee: Number(freshState.propertyDetails?.annualHoldingCosts?.propertyManagementFee),
            lettingFee: Number(freshState.propertyDetails?.annualHoldingCosts?.lettingFee),
            maintenanceCostPA: Number(freshState.propertyDetails?.annualHoldingCosts?.maintenanceCostPA),
            insurance: Number(freshState.propertyDetails?.annualHoldingCosts?.insurance),
            councilRates: Number(freshState.propertyDetails?.annualHoldingCosts?.councilRates),
            waterRates: Number(freshState.propertyDetails?.annualHoldingCosts?.waterRates),
            miscellaneous_costs: Number(freshState.propertyDetails?.annualHoldingCosts?.miscellaneous_costs),
            totalAnnualCosts: Number(freshState.propertyDetails?.annualHoldingCosts?.totalAnnualCosts)
          },
          acquisitionCosts: {
            deposit: Number(freshState.propertyDetails?.acquisitionCosts?.deposit),
            buildingAndPestInspection: Number(freshState.propertyDetails?.acquisitionCosts?.buildingAndPestInspection),
            buyersAgentFee: Number(freshState.propertyDetails?.acquisitionCosts?.buyersAgentFee),
            stampDuty: freshState.propertyDetails?.acquisitionCosts?.stampDuty,
            conveyancingFees: Number(freshState.propertyDetails?.acquisitionCosts?.conveyancingFees),
            depreciationSchedule: Number(freshState.propertyDetails?.acquisitionCosts?.depreciationSchedule),
            registrationFees: Number(freshState.propertyDetails?.acquisitionCosts?.registrationFees)
          }
        },
        cashFlowMetrics: {
          netRentalYield: Number(freshState.cashFlowMetrics?.netRentalYield),
          dscr: Number(freshState.cashFlowMetrics?.dscr),
          beforeTaxCashFlow: Number(freshState.cashFlowMetrics?.beforeTaxCashFlow),
          afterTaxCashFlow: Number(freshState.cashFlowMetrics?.afterTaxCashFlow)
        },
        expenses: {
          propertyManagementFee: Number(freshState.propertyDetails?.annualHoldingCosts?.propertyManagementFee),
          maintenanceCosts: Number(freshState.propertyDetails?.annualHoldingCosts?.maintenanceCostPA),
          insurance: Number(freshState.propertyDetails?.annualHoldingCosts?.insurance),
          councilRates: Number(freshState.propertyDetails?.annualHoldingCosts?.councilRates),
          waterRates: Number(freshState.propertyDetails?.annualHoldingCosts?.waterRates),
          miscellaneous: Number(freshState.propertyDetails?.annualHoldingCosts?.miscellaneous_costs),
          totalExpenses: Number(freshState.propertyDetails?.annualHoldingCosts?.totalAnnualCosts)
        },
        employmentIncome: {
          grossFamilyIncome: Number(freshState.employmentIncome?.grossFamilyIncome),
          otherTaxableIncome: Number(freshState.employmentIncome?.otherTaxableIncome),
          totalTaxableIncome: Number(freshState.employmentIncome?.totalTaxableIncome),
          taxBracket: freshState.employmentIncome?.taxBracket
        },
        taxAnalysis: {
          principalAndInterest: {
            totalDeductions: Number(freshState.taxAnalysis?.principalAndInterest?.totalDeductions),
            taxableIncome: Number(freshState.taxAnalysis?.principalAndInterest?.taxableIncome),
            taxPayable: Number(freshState.taxAnalysis?.principalAndInterest?.taxPayable)
          },
          interestOnly: {
            totalDeductions: Number(freshState.taxAnalysis?.interestOnly?.totalDeductions),
            taxableIncome: Number(freshState.taxAnalysis?.interestOnly?.taxableIncome),
            taxPayable: Number(freshState.taxAnalysis?.interestOnly?.taxPayable)
          }
        },
        projections: {
          capitalGrowthRate: Number(freshState.keyAssumptions?.capitalGrowthRate),
          rentalGrowthRate: Number(freshState.keyAssumptions?.rentalGrowthRate),
          inflationRate: Number(freshState.keyAssumptions?.inflationRate),
          vacancyRate: Number(freshState.keyAssumptions?.vacancyPeriod),
          yearlyProjections: {
            principalAndInterest: scenario1Data,
            interestOnly: scenario2Data
          },
          cumulativeProjections: freshState.cashFlowProjection?.cumulativeProjections || []
        },
        loanAnalysis,
        breakEvenAnalysis: {
          principalAndInterest: calculateBreakEvenDetailsForPayload('P&I'),
          interestOnly: calculateBreakEvenDetailsForPayload('IO')
        }
      };

      const response: APIResponse = await AuthenticatedAPI.request(
        'InvestmentAPI',
        'post',
        '/api/cashflow/ai-analysis-v2',
        {
          body: payload
        }
      );

      if (response.success && response.data) {
        const mapToFullTermAnalysisYear = (data: any): FullTermAnalysisYear => ({
          year: data.year || 0,
          payment: data.payment || 0,
          interestPaid: data.interestPaid || 0,
          principalPaid: data.principalPaid || 0,
          remainingLoan: data.remainingLoan || 0,
          interestRate: data.interestRate || 0,
          afterTaxCashFlow: data.afterTaxCashFlow || 0,
          propertyValue: data.propertyValue || 0,
          equity: data.equity || 0,
          grossRent: data.grossRent || 0,
          rentalExpenses: data.rentalExpenses || 0,
          beforeTaxCashFlow: data.beforeTaxCashFlow || 0
        });

        const safeAnalysis: AIAnalysisResponse = {
          cashFlowHealth: response.data.cashFlowHealth || [],
          loanTermAnalysis: response.data.loanTermAnalysis || [],
          riskAssessment: response.data.riskAssessment || [],
          loanMetrics: response.data.loanMetrics || {
            principalAndInterest: {},
            interestOnly: {}
          },
          fullTermAnalysis: {
            principalAndInterest: (response.data.fullTermAnalysis?.principalAndInterest || [])
              .map(mapToFullTermAnalysisYear),
            interestOnly: (response.data.fullTermAnalysis?.interestOnly || [])
              .map(mapToFullTermAnalysisYear)
          },
          aiSummary: response.data.aiSummary || {
            strengths: [],
            watchPoints: [],
            recommendations: []
          },
          propertyOverview: response.data.propertyOverview || {
            address: '',
            propertyValue: 0,
            weeklyRent: 0,
            annualRent: 0,
            lvr: 0,
            state: ''
          }
        };
        setAnalysis(safeAnalysis);
      } else {
        throw new Error(response.message || 'Failed to fetch analysis');
      }
    } catch (err) {
      console.error('Error fetching analysis:', err);
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  // Helper function to format currency
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  // Helper function to format percentage
  const formatPercentage = (value: number) => {
    return `${value.toFixed(1)}%`;
  };

  // Enhanced styling constants
  const sectionHeaderStyle = {
    fontWeight: 600,
    color: 'primary.main',
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mb: 2,
    '& .MuiSvgIcon-root': {
      color: 'primary.main',
      fontSize: '1.5rem'
    }
  };

  const cardStyle = {
    p: 3,
    borderRadius: 2,
    height: '100%',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)'
    }
  };

  const metricLabelStyle = {
    color: 'text.secondary',
    fontSize: '0.875rem',
    fontWeight: 500,
    mb: 0.5
  };

  const metricValueStyle = {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: 'text.primary',
    display: 'flex',
    alignItems: 'center',
    gap: 1
  };

  const positiveChangeStyle = {
    color: 'success.main',
    display: 'inline-flex',
    alignItems: 'center',
    gap: 0.5,
    fontSize: '0.875rem',
    fontWeight: 500
  };

  const negativeChangeStyle = {
    color: 'error.main',
    display: 'inline-flex',
    alignItems: 'center',
    gap: 0.5,
    fontSize: '0.875rem',
    fontWeight: 500
  };

  // Calculate when cash flow becomes positive after tax
  const calculateCashFlowPositiveYear = (scenario: 'P&I' | 'IO') => {
    const cashFlowYears = scenario === 'P&I' 
      ? latestCashFlowAnalysis.cashFlowProjection?.scenario1?.cashFlowYears
      : latestCashFlowAnalysis.cashFlowProjection?.scenario2?.cashFlowYears;

    if (!cashFlowYears?.length) return { year: 0, monthlyPositive: 0 };
    
    // Find first year with positive after-tax cash flow
    for (const yearData of cashFlowYears) {
      if (yearData.afterTaxCashFlow > 0) {
        return {
          year: yearData.year,
          monthlyPositive: yearData.afterTaxCashFlow / 12
        };
      }
    }

    // If never becomes positive, return the last year's data
    const lastYear = cashFlowYears[cashFlowYears.length - 1];
    return {
      year: lastYear.year,
      monthlyPositive: lastYear.afterTaxCashFlow / 12
    };
  };

  // Helper functions for calculations
  const calculateBreakEvenYear = (cashFlowYears: CashFlowYear[]): number => {
    if (!cashFlowYears.length) return 0;
    
    let cumulativeCashFlow = 0;
    for (const yearData of cashFlowYears) {
      cumulativeCashFlow += yearData.afterTaxCashFlow;
      if (cumulativeCashFlow > 0) {
        return yearData.year;
      }
    }
    return cashFlowYears[cashFlowYears.length - 1].year;
  };

  const calculateMonthlyPositive = (cashFlowYears: CashFlowYear[]): number => {
    if (!cashFlowYears.length) return 0;
    
    let cumulativeCashFlow = 0;
    for (const yearData of cashFlowYears) {
      cumulativeCashFlow += yearData.afterTaxCashFlow;
      if (cumulativeCashFlow > 0) {
        return yearData.afterTaxCashFlow / 12;
      }
    }
    return cashFlowYears[cashFlowYears.length - 1].afterTaxCashFlow / 12;
  };

  // Helper functions for accurate calculations
  const calculateMonthlyPayment = (scenario: string, year: number) => {
    if (!latestCashFlowAnalysis?.cashFlowProjection) return 0;
    const cashFlowYears = scenario === 'P&I' 
      ? latestCashFlowAnalysis.cashFlowProjection.scenario1?.cashFlowYears
      : latestCashFlowAnalysis.cashFlowProjection.scenario2?.cashFlowYears;
    
    if (!cashFlowYears?.[year]) return 0;
    
    return scenario === 'P&I'
      ? cashFlowYears[year].PrincipalInterestRepayment / 12
      : cashFlowYears[year].InterestOnlyRepayment / 12;
  };

  const calculateTotalInterest = (scenario: string) => {
    if (!latestCashFlowAnalysis?.cashFlowProjection) return 0;
    const cashFlowYears = scenario === 'P&I'
      ? latestCashFlowAnalysis.cashFlowProjection.scenario1?.cashFlowYears
      : latestCashFlowAnalysis.cashFlowProjection.scenario2?.cashFlowYears;
    
    if (!cashFlowYears) return 0;
    
    return cashFlowYears.reduce((sum: number, year: CashFlowYear) => 
      sum + (year.InterestCharged || 0), 0);
  };

  // Update the timeline data calculation to handle both loan types
  const getTimelineData = (year: number, loanType: 'P&I' | 'IO') => {
    const cashFlowYears = loanType === 'P&I'
      ? latestCashFlowAnalysis.cashFlowProjection?.scenario1?.cashFlowYears
      : latestCashFlowAnalysis.cashFlowProjection?.scenario2?.cashFlowYears;

    if (!cashFlowYears?.length) return null;

    // Find the exact year data
    const yearData = cashFlowYears.find((y: CashFlowYear) => y.year === year);
    if (!yearData) return null;

    // Calculate equity (property value - loan amount)
    const equity = yearData.propertyValue - yearData.loanAmount;
    
    // Calculate LVR (loan amount / property value * 100)
    const lvr = (yearData.loanAmount / yearData.propertyValue) * 100;

    return {
      equity,
      lvr: lvr.toFixed(0),
      propertyValue: yearData.propertyValue,
      loanAmount: yearData.loanAmount,
      year: yearData.year
    };
  };

  return (
    <>
      {/* AI Trigger Button */}
      <Tooltip 
        title={isAnalysisReady ? "View Investment Analysis" : "Complete property details including interest rate and loan term"} 
        placement="left"
      >
        <span>
          <Fab
            color="primary"
            disabled={!isAnalysisReady}
            sx={{
              position: 'fixed',
              right: 20,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1000,
              background: 'linear-gradient(135deg, #4cceac 0%, #2196f3 100%)',
              transition: 'all 0.3s ease',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              '&:hover': { 
                background: 'linear-gradient(135deg, #3bbd9b 0%, #1976d2 100%)',
                transform: 'translateY(-50%) scale(1.05)',
              },
              width: 64,
              height: 64
            }}
            onClick={() => isAnalysisReady && setDrawerOpen(true)}
          >
            <Badge
              badgeContent={isAnalysisReady ? "AI" : "🔒"}
              color="secondary"
              sx={{
                '& .MuiBadge-badge': {
                  background: isAnalysisReady ? 
                    'linear-gradient(135deg, #4cceac 0%, #2196f3 100%)' : 
                    'linear-gradient(135deg, #999 0%, #666 100%)',
                  color: 'white',
                }
              }}
            >
              <SmartToy sx={{ fontSize: 28, color: 'white' }} />
            </Badge>
          </Fab>
        </span>
      </Tooltip>

      {/* Analysis Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: '65%',
            maxWidth: '1200px',
            minWidth: '400px',
            bgcolor: '#f8fafc',
            p: 4,
          }
        }}
      >
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
          {/* Enhanced Header */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 4,
            pb: 2,
            borderBottom: '2px solid',
            borderColor: 'primary.light'
          }}>
            <Typography variant="h4" sx={{ 
              fontWeight: 700, 
              display: 'flex', 
              alignItems: 'center', 
              gap: 2,
              background: 'linear-gradient(135deg, #4cceac 0%, #2196f3 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}>
              <SmartToy sx={{ fontSize: 32, color: '#2196f3' }} /> AI Investment Analysis
            </Typography>
            <Box>
              <IconButton 
                onClick={() => setDrawerOpen(false)} 
                sx={{ 
                  mr: 2,
                  '&:hover': { 
                    bgcolor: 'rgba(33, 150, 243, 0.1)' 
                  }
                }}
              >
                <Close />
              </IconButton>
              <Button
                startIcon={<Refresh />}
                onClick={fetchAnalysis}
                disabled={loading}
                variant="contained"
                sx={{ 
                  background: 'linear-gradient(135deg, #4cceac 0%, #2196f3 100%)',
                  px: 3,
                  py: 1,
                  '&:hover': {
                    background: 'linear-gradient(135deg, #3bbd9b 0%, #1976d2 100%)'
                  },
                  '& .MuiButton-startIcon': {
                    animation: loading ? `${pulseAnimation} 1.5s infinite` : 'none'
                  }
                }}
              >
                {loading ? 'AI Analyzing...' : 'Refresh AI Analysis'}
              </Button>
            </Box>
          </Box>

          {/* Enhanced Content */}
          {loading ? (
            <Box display="flex" flexDirection="column" gap={3} alignItems="center" justifyContent="center" flex={1}>
              <CircularProgress 
                size={80} 
                thickness={4}
              sx={{ 
                  color: 'primary.main',
                  '& .MuiCircularProgress-circle': {
                    strokeLinecap: 'round',
                  }
                }}
              />
              <Typography variant="h5" color="primary" sx={{ fontWeight: 500 }}>
                Analyzing your investment...
                </Typography>
              <Typography variant="body1" color="text.secondary" align="center">
                Our AI is crunching the numbers to provide you with comprehensive insights
                </Typography>
            </Box>
          ) : error ? (
            <Alert 
              severity="error"
              variant="filled"
              sx={{ 
                borderRadius: 2,
                '& .MuiAlert-icon': {
                  fontSize: '2rem'
                }
              }}
            >
              <AlertTitle sx={{ fontWeight: 600 }}>Error</AlertTitle>
              {error}
            </Alert>
          ) : analysis ? (
            <Box sx={{ overflowY: 'auto', flex: 1 }}>
              <Grid container spacing={3}>
                {/* Property Overview Card */}
                <Grid item xs={12}>
                  <Paper sx={{ 
                    ...cardStyle,
                    background: 'linear-gradient(135deg, rgba(76, 206, 172, 0.1), rgba(33, 150, 243, 0.1))',
                    border: '1px solid',
                    borderColor: 'primary.light',
                  }}>
                    <Typography variant="h5" sx={sectionHeaderStyle}>
                      <AccountBalance /> Property Overview
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={3}>
                        <Box>
                          <Typography sx={metricLabelStyle}>Property Address</Typography>
                          <Typography sx={metricValueStyle}>
                            <LocationOn sx={{ color: 'primary.main', fontSize: '1.2rem' }} />
                            {latestCashFlowAnalysis.propertyDetails?.address || 'N/A'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Box>
                          <Typography sx={metricLabelStyle}>Purchase Price</Typography>
                          <Typography sx={metricValueStyle}>
                            <AttachMoney sx={{ color: 'success.main', fontSize: '1.2rem' }} />
                            {formatCurrency(Number(latestCashFlowAnalysis.propertyDetails?.propertyValue || 0))}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Box>
                          <Typography sx={metricLabelStyle}>Weekly Rent</Typography>
                          <Typography sx={metricValueStyle}>
                            <Payments sx={{ color: 'primary.main', fontSize: '1.2rem' }} />
                            ${Number(latestCashFlowAnalysis.propertyDetails?.estimatedWeeklyRent || 0)}
                            <Typography variant="caption" color="text.secondary">
                              ({formatCurrency(Number(latestCashFlowAnalysis.propertyDetails?.estimatedWeeklyRent || 0) * 52)} p.a.)
                            </Typography>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Box>
                          <Typography sx={metricLabelStyle}>LVR</Typography>
                          <Typography sx={metricValueStyle}>
                            <AccountBalance sx={{ color: 'warning.main', fontSize: '1.2rem' }} />
                            {Number(latestCashFlowAnalysis.propertyDetails?.lvr || 0).toFixed(1)}%
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {/* AI Summary Section - Moved below Property Overview */}
                <Grid item xs={12}>
                  <Paper sx={{ 
                    ...cardStyle,
                    background: 'linear-gradient(135deg, rgba(33, 150, 243, 0.05), rgba(76, 206, 172, 0.05))',
                    border: '1px solid',
                    borderColor: 'primary.light',
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                      <AutoAwesome sx={{ color: '#2196f3', fontSize: '2rem' }} />
                      <Typography variant="h5" sx={sectionHeaderStyle}>
                        AI Investment Summary
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1" sx={{ mb: 2, fontWeight: 500 }}>
                        Based on comprehensive analysis of your investment at {analysis?.propertyOverview?.address}:
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <Box sx={{ 
                            p: 2, 
                            bgcolor: 'rgba(76, 206, 172, 0.1)', 
                            borderRadius: 2,
                            height: '100%'
                          }}>
                            <Typography sx={{ 
                              color: 'success.main',
                              fontWeight: 600,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              mb: 1
                            }}>
                              <CheckCircle /> Strengths
                            </Typography>
                            {analysis?.aiSummary?.strengths.map((strength, index) => (
                              <Typography key={index} variant="body2">
                                {strength}
                              </Typography>
                            ))}
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Box sx={{ 
                            p: 2, 
                            bgcolor: 'rgba(255, 152, 0, 0.1)', 
                            borderRadius: 2,
                            height: '100%'
                          }}>
                            <Typography sx={{ 
                              color: 'warning.main',
                              fontWeight: 600,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              mb: 1
                            }}>
                              <Warning /> Watch Points
                            </Typography>
                            {analysis?.aiSummary?.watchPoints.map((point, index) => (
                              <Typography key={index} variant="body2">
                                {point}
                              </Typography>
                            ))}
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Box sx={{ 
                            p: 2, 
                            bgcolor: 'rgba(33, 150, 243, 0.1)', 
                            borderRadius: 2,
                            height: '100%'
                          }}>
                            <Typography sx={{ 
                              color: 'primary.main',
                              fontWeight: 600,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              mb: 1
                            }}>
                              <Lightbulb /> Recommendations
                            </Typography>
                            {analysis?.aiSummary?.recommendations.map((recommendation, index) => (
                              <Typography key={index} variant="body2">
                                {recommendation}
                              </Typography>
                            ))}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>

                {/* Investment Returns - Temporarily Hidden
                <Grid item xs={12}>
                  <Paper sx={cardStyle}>
                    <Typography variant="h5" sx={sectionHeaderStyle}>
                      <TrendingUp /> Investment Returns
                    </Typography>
                    // ... rest of Investment Returns section ...
                  </Paper>
                </Grid>
                */}

                {/* Loan Strategy Comparison */}
                <Grid item xs={12}>
                  <Paper sx={{ 
                    ...cardStyle,
                    background: 'linear-gradient(135deg, rgba(76, 206, 172, 0.05), rgba(33, 150, 243, 0.05))',
                    border: '1px solid',
                    borderColor: 'primary.light',
                  }}>
                    <Typography variant="h5" sx={sectionHeaderStyle}>
                      <CompareArrows /> Loan Strategy Comparison
                    </Typography>
                    <Grid container spacing={3}>
                      {/* Monthly Payments */}
                      <Grid item xs={12} md={4}>
                        <Box>
                          <Typography sx={{ ...metricLabelStyle, fontSize: '1rem' }}>Monthly Payments</Typography>
                          <Stack spacing={2}>
                            <Box>
                              <Typography sx={metricLabelStyle}>Principal & Interest</Typography>
                              <Typography sx={metricValueStyle}>
                                <TrendingUp sx={{ color: 'warning.main', fontSize: '1.2rem' }} />
                                {formatCurrency(calculateMonthlyPayment('P&I', 0))}
                                <ArrowForward sx={{ color: 'text.secondary', fontSize: '1rem', mx: 0.5 }} />
                                {formatCurrency(calculateMonthlyPayment('P&I', 29))}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography sx={metricLabelStyle}>Interest Only</Typography>
                              <Typography sx={metricValueStyle}>
                                <TrendingUp sx={{ color: 'info.main', fontSize: '1.2rem' }} />
                                {formatCurrency(calculateMonthlyPayment('IO', 0))}
                                <Chip 
                                  label="Fixed"
                                  size="small"
                                  sx={{ 
                                    ml: 1,
                                    bgcolor: 'info.main',
                                    color: 'white',
                                    fontWeight: 500
                                  }}
                                />
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>

                      {/* Total Interest */}
                      <Grid item xs={12} md={4}>
                        <Box>
                          <Typography sx={{ ...metricLabelStyle, fontSize: '1rem' }}>Total Interest</Typography>
                          <Stack spacing={2}>
                            <Box>
                              <Typography sx={metricLabelStyle}>Principal & Interest</Typography>
                              <Typography sx={{ ...metricValueStyle, color: 'error.main' }}>
                                <Warning sx={{ color: 'error.main', fontSize: '1.2rem' }} />
                                {formatCurrency(calculateTotalInterest('P&I'))}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography sx={metricLabelStyle}>Interest Only</Typography>
                              <Typography sx={{ ...metricValueStyle, color: 'error.main' }}>
                                <Warning sx={{ color: 'error.main', fontSize: '1.2rem' }} />
                                {formatCurrency(calculateTotalInterest('IO'))}
                              </Typography>
                            </Box>
                            <Typography sx={positiveChangeStyle}>
                              <Savings sx={{ fontSize: '1rem' }} />
                              Save {formatCurrency(calculateTotalInterest('IO') - calculateTotalInterest('P&I'))} with P&I
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>

                      {/* Cash Flow Timeline */}
                      <Grid item xs={12} md={4}>
                        <Box>
                          <Typography sx={{ ...metricLabelStyle, fontSize: '1rem' }}>Cash Flow Positive</Typography>
                          <Stack spacing={2}>
                            <Box>
                              <Typography sx={metricLabelStyle}>Principal & Interest</Typography>
                              <Typography sx={metricValueStyle}>
                                <Timeline sx={{ color: 'success.main', fontSize: '1.2rem' }} />
                                {(() => {
                                  const { year, monthlyPositive } = calculateCashFlowPositiveYear('P&I');
                                  return (
                                    <>
                                      Year {year}
                                      <Chip 
                                        label={`${monthlyPositive > 0 ? '+' : ''}${formatCurrency(monthlyPositive)}/month`}
                                        size="small"
                                        color={monthlyPositive > 0 ? 'success' : 'error'}
                                        sx={{ ml: 1, fontWeight: 500 }}
                                      />
                                    </>
                                  );
                                })()}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography sx={metricLabelStyle}>Interest Only</Typography>
                              <Typography sx={metricValueStyle}>
                                <Timeline sx={{ color: 'success.main', fontSize: '1.2rem' }} />
                                {(() => {
                                  const { year, monthlyPositive } = calculateCashFlowPositiveYear('IO');
                                  return (
                                    <>
                                      Year {year}
                                      <Chip 
                                        label={`${monthlyPositive > 0 ? '+' : ''}${formatCurrency(monthlyPositive)}/month`}
                                        size="small"
                                        color={monthlyPositive > 0 ? 'success' : 'error'}
                                        sx={{ ml: 1, fontWeight: 500 }}
                                      />
                                    </>
                                  );
                                })()}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {/* Cash Flow Health - Temporarily Hidden
                <Grid item xs={12}>
                  <Paper sx={cardStyle}>
                    <Typography variant="h5" sx={sectionHeaderStyle}>
                      <MonetizationOn /> Cash Flow Health
                    </Typography>
                    // ... rest of Cash Flow Health section ...
                  </Paper>
                </Grid>
                */}

                {/* Risk Management and Optimization Opportunities */}
                <Grid item xs={12}>
                  <Paper sx={{ 
                    ...cardStyle,
                    background: 'linear-gradient(135deg, rgba(76, 206, 172, 0.05), rgba(33, 150, 243, 0.05))',
                    border: '1px solid',
                    borderColor: 'primary.light',
                  }}>
                    <Typography variant="h5" sx={sectionHeaderStyle}>
                      <TrendingUp /> Optimization Opportunities
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <Box>
                          <Typography sx={{ ...metricLabelStyle, fontSize: '1rem' }}>Loan Security</Typography>
                          <Box sx={{ 
                            p: 2.5,
                            borderRadius: 2,
                            bgcolor: 'rgba(76, 206, 172, 0.08)',
                            border: '1px solid',
                            borderColor: 'success.light'
                          }}>
                            <Stack spacing={1.5}>
                              <Typography sx={{ color: 'success.main', fontSize: '0.95rem' }}>• Additional principal payments potential</Typography>
                              <Typography sx={{ color: 'success.main', fontSize: '0.95rem' }}>• Property improvement opportunities</Typography>
                              <Typography sx={{ color: 'success.main', fontSize: '0.95rem' }}>• Tax structure optimization</Typography>
                            </Stack>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box>
                          <Typography sx={{ ...metricLabelStyle, fontSize: '1rem' }}>Income Security</Typography>
                          <Box sx={{ 
                            p: 2.5,
                            borderRadius: 2,
                            bgcolor: 'rgba(33, 150, 243, 0.08)',
                            border: '1px solid',
                            borderColor: 'primary.light'
                          }}>
                            <Stack spacing={1.5}>
                              <Typography sx={{ color: 'primary.main', fontSize: '0.95rem' }}>• Refinancing at Year 5 (~82% LVR)</Typography>
                              <Typography sx={{ color: 'primary.main', fontSize: '0.95rem' }}>• Rent increase potential</Typography>
                              <Typography sx={{ color: 'primary.main', fontSize: '0.95rem' }}>• Cost optimization review</Typography>
                            </Stack>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box>
                          <Typography sx={{ ...metricLabelStyle, fontSize: '1rem' }}>Market Position</Typography>
                          <Box sx={{ 
                            p: 2.5,
                            borderRadius: 2,
                            bgcolor: 'rgba(255, 152, 0, 0.08)',
                            border: '1px solid',
                            borderColor: 'warning.light'
                          }}>
                            <Stack spacing={1.5}>
                              <Typography sx={{ color: 'warning.main', fontSize: '0.95rem' }}>• Major equity milestone at Year 10</Typography>
                              <Typography sx={{ color: 'warning.main', fontSize: '0.95rem' }}>• Renovation/value-add potential</Typography>
                              <Typography sx={{ color: 'warning.main', fontSize: '0.95rem' }}>• Debt recycling opportunities</Typography>
                            </Stack>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {/* Investment Timeline */}
                <Grid item xs={12}>
                  <Paper sx={{ 
                    ...cardStyle,
                    background: 'linear-gradient(135deg, rgba(33, 150, 243, 0.05), rgba(76, 206, 172, 0.05))',
                    border: '1px solid',
                    borderColor: 'primary.light',
                  }}>
                    <Typography variant="h5" sx={sectionHeaderStyle}>
                      <Timeline /> Investment Timeline
                    </Typography>
                    
                    <Tabs 
                      value={timelineTab}
                      onChange={(_, newValue) => setTimelineTab(newValue)}
                      sx={{ 
                        mb: 3,
                        '& .MuiTabs-flexContainer': {
                          gap: 2
                        },
                        '& .MuiTabs-indicator': {
                          display: 'none'
                        },
                        '& .MuiTab-root': {
                          minHeight: 45,
                          minWidth: 180,
                          borderRadius: 2,
                          fontSize: '0.95rem',
                          fontWeight: 500,
                          color: 'text.secondary',
                          border: '1px solid',
                          borderColor: 'divider',
                          transition: 'all 0.2s ease-in-out',
                          '&.Mui-selected': {
                            color: 'white',
                            background: 'linear-gradient(90deg, #4cceac 0%, #2196f3 100%)',
                            borderColor: 'transparent',
                            fontWeight: 600,
                          },
                          '&:not(.Mui-selected):hover': {
                            backgroundColor: 'rgba(76, 206, 172, 0.08)',
                            borderColor: '#4cceac',
                            color: '#4cceac'
                          }
                        }
                      }}
                    >
                      <Tab 
                        label={
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 1,
                            py: 0.5,
                          }}>
                            <Receipt sx={{ fontSize: '1.1rem' }} />
                            Interest Only
                          </Box>
                        }
                        value="IO"
                      />
                      <Tab 
                        label={
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 1,
                            py: 0.5,
                          }}>
                            <Payments sx={{ fontSize: '1.1rem' }} />
                            Principal & Interest
                          </Box>
                        }
                        value="P&I"
                      />
                    </Tabs>

                    <Grid container spacing={3}>
                      {[5, 10, 15, 20, 30].map((year) => {
                        const data = getTimelineData(year, timelineTab);
                        if (!data) return null;

                        return (
                          <Grid item xs={12} md={year === 30 ? 12 : 6} key={year}>
                            <Box sx={{ 
                              p: 2, 
                              border: '1px solid',
                              borderColor: 'primary.light',
                              borderRadius: 2,
                              bgcolor: 'background.paper',
                              position: 'relative',
                              overflow: 'hidden'
                            }}>
                              <Box sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: 4,
                                bgcolor: 'primary.main',
                                background: 'linear-gradient(90deg, #4cceac 0%, #2196f3 100%)',
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                              }} />
                              <Typography sx={{ ...metricLabelStyle, fontSize: '1rem', mt: 1 }}>
                                <Timeline sx={{ mr: 1, color: 'primary.main' }} /> Year {year}
                              </Typography>
                              <Stack spacing={2} sx={{ mt: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <AccountBalance sx={{ color: 'success.main', fontSize: '1.2rem' }} />
                                  <Typography sx={metricValueStyle}>
                                    Equity: 
                                    <Chip 
                                      label={formatCurrency(data.equity)}
                                      size="small"
                                      color="success"
                                      sx={{ ml: 1, fontWeight: 500 }}
                                    />
                                  </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <ShowChart sx={{ color: 'warning.main', fontSize: '1.2rem' }} />
                                  <Typography sx={metricValueStyle}>
                                    LVR: 
                                    <Chip 
                                      label={`${data.lvr}%${year === 5 ? ' (Consider refinancing)' : ''}`}
                                      size="small"
                                      color={Number(data.lvr) > 80 ? 'warning' : 'success'}
                                      sx={{ ml: 1, fontWeight: 500 }}
                                    />
                                  </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <PriceCheck sx={{ color: 'primary.main', fontSize: '1.2rem' }} />
                                  <Typography sx={metricValueStyle}>
                                    Property Value: 
                                    <Chip 
                                      label={formatCurrency(data.propertyValue)}
                                      size="small"
                                      color="primary"
                                      sx={{ ml: 1, fontWeight: 500 }}
                                    />
                                  </Typography>
                                </Box>
                              </Stack>
                              <LinearProgress 
                                variant="determinate" 
                                value={year === 30 ? 100 : (year / 30) * 100}
                                sx={{ 
                                  height: 8, 
                                  borderRadius: 4,
                                  mt: 2,
                                  bgcolor: 'rgba(76, 206, 172, 0.1)',
                                  '& .MuiLinearProgress-bar': {
                                    background: 'linear-gradient(90deg, #4cceac 0%, #2196f3 100%)',
                                    borderRadius: 4
                                  }
                                }}
                              />
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Paper>
                </Grid>

                {/* Tax Optimization */}
                <Grid item xs={12}>
                  <Paper sx={{ 
                    ...cardStyle,
                    background: 'linear-gradient(135deg, rgba(76, 206, 172, 0.05), rgba(33, 150, 243, 0.05))',
                    border: '1px solid',
                    borderColor: 'primary.light',
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
                      <Receipt sx={{ color: 'text.primary', fontSize: '1.5rem' }} />
                      <Typography variant="h5" sx={{ 
                        color: 'text.primary',
                        fontWeight: 600,
                        fontSize: '1.25rem'
                      }}>
                        Tax Optimization
                      </Typography>
                    </Box>

                    <Grid container spacing={3}>
                      {/* Current Tax Position */}
                      <Grid item xs={12} md={4}>
                        <Box>
                          <Typography sx={{ 
                            color: 'text.secondary',
                            fontSize: '1.1rem',
                            mb: 2
                          }}>
                            Current Tax Position (First Year)
                          </Typography>
                          <Stack spacing={2.5}>
                            {(() => {
                              // Get first year data from the selected scenario
                              const firstYearData = latestCashFlowAnalysis.cashFlowProjection?.scenario1?.cashFlowYears?.[0];
                              
                              // Calculate total deductions
                              const totalDeductions = firstYearData ? (
                                firstYearData.InterestCharged + // Interest
                                firstYearData.rentalExpenses + // All rental expenses
                                (Number(latestCashFlowAnalysis.propertyDetails?.propertyValue || 0) * 0.025) // Building depreciation
                              ) : 0;
                              
                              // Calculate tax benefit using actual tax rate
                              const grossFamilyIncome = Number(latestCashFlowAnalysis.employmentIncome?.grossFamilyIncome || 0);
                              const otherTaxableIncome = Number(latestCashFlowAnalysis.employmentIncome?.otherTaxableIncome || 0);
                              const taxDetails = calculateTaxWithDetails(grossFamilyIncome + otherTaxableIncome);
                              const effectiveTaxRate = Number(taxDetails.taxSlab) / 100;
                              
                              const taxBenefit = totalDeductions * effectiveTaxRate;

                              return (
                                <>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                                      <Savings sx={{ color: 'success.main', fontSize: '1.25rem' }} />
                                      <Typography sx={{ color: 'text.primary', fontSize: '1rem' }}>Total Deductions:</Typography>
                                    </Box>
                                    <Chip 
                                      label={formatCurrency(totalDeductions)}
                                      size="medium"
                                      sx={{ 
                                        bgcolor: 'success.main',
                                        color: 'white',
                                        fontWeight: 500,
                                        fontSize: '0.95rem',
                                        height: '32px',
                                        minWidth: '100px'
                                      }}
                                    />
                                  </Box>

                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                                      <TrendingUp sx={{ color: 'success.main', fontSize: '1.25rem' }} />
                                      <Typography sx={{ color: 'text.primary', fontSize: '1rem' }}>Tax Benefit:</Typography>
                                    </Box>
                                    <Chip 
                                      label={formatCurrency(taxBenefit)}
                                      size="medium"
                                      sx={{ 
                                        bgcolor: 'success.main',
                                        color: 'white',
                                        fontWeight: 500,
                                        fontSize: '0.95rem',
                                        height: '32px',
                                        minWidth: '100px'
                                      }}
                                    />
                                  </Box>

                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                                      <ShowChart sx={{ color: 'warning.main', fontSize: '1.25rem' }} />
                                      <Typography sx={{ color: 'text.primary', fontSize: '1rem' }}>Effective Tax Rate:</Typography>
                                    </Box>
                                    <Chip 
                                      label={`${(effectiveTaxRate * 100).toFixed(1)}%`}
                                      size="medium"
                                      sx={{ 
                                        bgcolor: 'warning.main',
                                        color: 'white',
                                        fontWeight: 500,
                                        fontSize: '0.95rem',
                                        height: '32px',
                                        minWidth: '80px'
                                      }}
                                    />
                                  </Box>
                                </>
                              );
                            })()}
                          </Stack>
                        </Box>
                      </Grid>

                      {/* Tax Efficiency */}
                      <Grid item xs={12} md={4}>
                        <Box>
                          <Typography sx={{ 
                            color: 'text.secondary',
                            fontSize: '1.1rem',
                            mb: 2
                          }}>
                            Tax Efficiency Comparison (First Year)
                          </Typography>
                          <Stack spacing={2.5}>
                            {(() => {
                              // Get first year data from both scenarios
                              const piFirstYear = latestCashFlowAnalysis.cashFlowProjection?.scenario1?.cashFlowYears?.[0];
                              const ioFirstYear = latestCashFlowAnalysis.cashFlowProjection?.scenario2?.cashFlowYears?.[0];
                              
                              // Calculate total deductions for each scenario
                              const buildingDepreciation = Number(latestCashFlowAnalysis.propertyDetails?.propertyValue || 0) * 0.025;
                              
                              const piDeductions = piFirstYear ? (
                                piFirstYear.InterestCharged +
                                piFirstYear.rentalExpenses +
                                buildingDepreciation
                              ) : 0;
                              
                              const ioDeductions = ioFirstYear ? (
                                ioFirstYear.InterestCharged +
                                ioFirstYear.rentalExpenses +
                                buildingDepreciation
                              ) : 0;
                              
                              const taxDifference = ioDeductions - piDeductions;
                              
                              return (
                                <>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                                      <CompareArrows sx={{ color: 'text.primary', fontSize: '1.25rem' }} />
                                      <Typography sx={{ color: 'text.primary', fontSize: '1rem' }}>P&I Deductions:</Typography>
                                    </Box>
                                    <Chip 
                                      label={formatCurrency(piDeductions)}
                                      size="medium"
                                      sx={{ 
                                        bgcolor: 'black',
                                        color: 'white',
                                        fontWeight: 500,
                                        fontSize: '0.95rem',
                                        height: '32px',
                                        minWidth: '100px'
                                      }}
                                    />
                                  </Box>

                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                                      <CompareArrows sx={{ color: 'text.primary', fontSize: '1.25rem' }} />
                                      <Typography sx={{ color: 'text.primary', fontSize: '1rem' }}>IO Deductions:</Typography>
                                    </Box>
                                    <Chip 
                                      label={formatCurrency(ioDeductions)}
                                      size="medium"
                                      sx={{ 
                                        bgcolor: 'black',
                                        color: 'white',
                                        fontWeight: 500,
                                        fontSize: '0.95rem',
                                        height: '32px',
                                        minWidth: '100px'
                                      }}
                                    />
                                  </Box>

                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                                      <TrendingUp sx={{ color: 'success.main', fontSize: '1.25rem' }} />
                                      <Typography sx={{ color: 'text.primary', fontSize: '1rem' }}>Annual Tax Savings:</Typography>
                                    </Box>
                                    <Chip 
                                      label={`${formatCurrency(Math.abs(taxDifference))} with ${taxDifference > 0 ? 'IO' : 'P&I'}`}
                                      size="medium"
                                      sx={{ 
                                        bgcolor: 'success.main',
                                        color: 'white',
                                        fontWeight: 500,
                                        fontSize: '0.95rem',
                                        height: '32px',
                                        minWidth: '140px'
                                      }}
                                    />
                                  </Box>
                                </>
                              );
                            })()}
                          </Stack>
                        </Box>
                      </Grid>

                      {/* Depreciation Benefits */}
                      <Grid item xs={12} md={4}>
                        <Box>
                          <Typography sx={{ 
                            color: 'text.secondary',
                            fontSize: '1.1rem',
                            mb: 2
                          }}>
                            Annual Depreciation Schedule
                          </Typography>
                          <Stack spacing={2.5}>
                            {(() => {
                              const propertyValue = Number(latestCashFlowAnalysis.propertyDetails?.propertyValue || 0);
                              const buildingDepreciation = propertyValue * 0.025; // 2.5% of property value
                              const yearlyTaxSaving = buildingDepreciation * (Number(calculateTaxWithDetails(
                                Number(latestCashFlowAnalysis.employmentIncome?.grossFamilyIncome || 0) +
                                Number(latestCashFlowAnalysis.employmentIncome?.otherTaxableIncome || 0)
                              ).taxSlab) / 100);

                              return (
                                <>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                                      <AccountBalance sx={{ color: 'text.primary', fontSize: '1.25rem' }} />
                                      <Typography sx={{ color: 'text.primary', fontSize: '1rem' }}>Building (2.5%):</Typography>
                                    </Box>
                                    <Chip 
                                      label={`${formatCurrency(buildingDepreciation)}/year`}
                                      size="medium"
                                      sx={{ 
                                        bgcolor: 'black',
                                        color: 'white',
                                        fontWeight: 500,
                                        fontSize: '0.95rem',
                                        height: '32px',
                                        minWidth: '120px'
                                      }}
                                    />
                                  </Box>

                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                                      <TrendingUp sx={{ color: 'success.main', fontSize: '1.25rem' }} />
                                      <Typography sx={{ color: 'text.primary', fontSize: '1rem' }}>Tax Savings:</Typography>
                                    </Box>
                                    <Chip 
                                      label={`${formatCurrency(yearlyTaxSaving)}/year`}
                                      size="medium"
                                      sx={{ 
                                        bgcolor: 'success.main',
                                        color: 'white',
                                        fontWeight: 500,
                                        fontSize: '0.95rem',
                                        height: '32px',
                                        minWidth: '120px'
                                      }}
                                    />
                                  </Box>
                                </>
                              );
                            })()}
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Alert 
              severity="info"
              icon={<SmartToy sx={{ fontSize: '2rem' }} />}
              sx={{ 
                bgcolor: 'primary.light', 
                color: 'primary.contrastText',
                borderRadius: 2,
                '& .MuiAlert-icon': { 
                  color: 'primary.contrastText',
                  fontSize: '2rem'
                }
              }}
            >
              <AlertTitle sx={{ fontWeight: 600, fontSize: '1.2rem' }}>Get Investment Insights</AlertTitle>
              <Typography variant="body1">
                Click the Refresh Analysis button to receive AI-powered insights about your investment strategy.
              </Typography>
            </Alert>
          )}

          {/* Enhanced Footnote */}
          <Box sx={{ 
            mt: 'auto', 
            pt: 3,
            pb: 1, 
            borderTop: '2px solid',
            borderColor: 'primary.light',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
            <InfoOutlined sx={{ fontSize: '1rem', color: 'text.secondary' }} />
            <Typography variant="caption" color="text.secondary" sx={{ fontStyle: 'italic' }}>
              AI-generated insights are for guidance purposes only and should not be considered as financial advice.
            </Typography>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default CashFlowAIAnalysis;